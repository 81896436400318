import { useRouteError } from "react-router-dom";
import React, { useState, useEffect } from "react";
import RouterUtility from "../../utilites/routerUtils";
//import RefreshTimer from "../../utilites/Components/RefreshTimer";
import { safePage } from "../../utilites/Globals/Globals";

export default function ErrorPage() {
  const error:any = useRouteError();
  const routerUtility = new RouterUtility;

  const sTimestamp = new Date();
  sTimestamp.setSeconds(sTimestamp.getSeconds() + 600)
  const [expiryTimestamp, setExpiryTimestamp] = useState<Date>(sTimestamp);

  const [forceRefresh, setForceRefresh] = useState<boolean>(true);


  //wait 10 minutes then retry the page...
  useEffect(() => {
    console.error("in error:", error);
    const RetryPage = async ()=> {
      console.log(routerUtility);
      routerUtility.linkTo(null, routerUtility.location);
    }

    if(forceRefresh === false){

      RetryPage().catch((e) => console.error("Retry error", e)).then(() => {
        console.log("setting timer")
        const nTimestamp = new Date();
        nTimestamp.setSeconds(nTimestamp.getSeconds() + 600)
        setExpiryTimestamp(nTimestamp);   
        setForceRefresh(true)
      })
    }

  }, [forceRefresh]);

  const handleClick = (e) => {
    routerUtility.linkTo(e, routerUtility.location);
  }

  return (
    <div className="flex flex-col h-screen w-screen align-center items-center justify-center text-center bg-gray-500 space-y-2">

      <div className="flex flex-col flex-none justify-center align-center items-center bg-white p-2 rounded-md border-2 border-red-800 overflow-hidden drop-shadow-lg">
        <label className="text-red-500 font-semibold flex flex-row ">
          <p>Retrying in: </p>
          {/* <RefreshTimer forceRefresh={forceRefresh} setForceRefresh={setForceRefresh} expiryTimestamp={expiryTimestamp}/>load manager */}
        </label>

        <nav className="flex flex-row h-full w-full justify-center align-center items-center text-center text-lg font-semibold space-x-2">
            <button className="bg-amber-500 text-white border border-amber-800 shadow rounded-md p-2" >
              <a id="error-page" href={safePage}>
                <p>Return to safety</p>
              </a>
            </button>

            <button className="bg-red-500 text-white border border-red-800 shadow rounded-md p-2" onClick={(e) => handleClick(e)}>
                <p>Retry Now</p>
            </button>
        </nav>
      </div>

      <h1 className="flex flex-row space-x-2">
        <p>Oops!, an unexpected error has occurred:</p>
        <i>{routerUtility.location.pathname}</i>
        <i>{error && (error.statusText || error.message)? error.statusText || error.message: "Error!"}</i>
      </h1>

    </div>
  );
}