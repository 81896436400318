import { HiPlusCircle } from "react-icons/hi";
import clsx from "clsx";
import { useMemo, useState } from "react";
import AddEmployeeSlideover from "../../utilites/slideOvers/AddEmployeeSlideover";
import EditEmployeeSlideover from "../../utilites/slideOvers/EditEmployeeSlideover";
import { UserAccount } from "../../utilites/Decoders/Decoders";
import React, { useEffect } from "react";
import { RTStyles } from "../../utilites/Globals/Globals";
import {
  FaLayerGroup,
  FaAngleDoubleDown,
  FaAngleDoubleUp,
  FaAngleRight,
} from "react-icons/fa";
import {
  BiArrowToLeft,
  BiArrowToRight,
  BiRefresh,
  BiColumns,
  BiX as FiX,
  BiX,
} from "react-icons/bi";
import { RiPencilRuler2Line } from "react-icons/ri";
import {
  getCellStyles,
  customReports,
} from "../../utilites/ConfigUtils/EmployeesFunctions";
import {
  //Column,
  createTable,
  ColumnDef,
  useTableInstance,
  //ColumnFiltersState,
  getCoreRowModel,
  //getFilteredRowModel,
  getPaginationRowModel,
  //sortingFns,
  //sortingFn,
  getSortedRowModel,
  ColumnSizing,
  ColumnResizeMode,
  SortingState,
  GroupingState,
  getGroupedRowModel,
  getExpandedRowModel,
  columnOrderState,
  //flexRender,
  //useReactTable,
  VisibilityState,
  PaginationState,
} from "@tanstack/react-table";
import EmployeeAccountApi from "../../utilites/Api/EmployeeAccountApi";
import KeyValueListBox from "../../utilites/slideOvers/KeyValueListBox";
//import uuid from "react-uuid";

type MyTableGenerics = typeof table.generics;
const table = createTable().setRowType<UserAccount>().setTableMetaType<{
  updateData: (rowIndex: number, columnId: string, value: unknown) => void; //custom table function, not cur. in use, but used to make editable cells
}>();

const defaultColumn: Partial<ColumnDef<MyTableGenerics>> = {
  cell: ({ getValue, row: { index }, column: { id }, instance }) => {
    const onAction = () => {
      //log the cell/table state on click
      let tableState = instance.getState();
      console.log(id, tableState);
    };

    return (
      //the html used by all default cells (not placeholders, aggregates, header, etc.)
      <li className={"text-left list-none"} onClick={() => onAction()}>
        <p>{getValue()}</p>
      </li>
    );
  },
};

//using legacy api system, if memory leak errors start occuring switch to use parent api
const Employees: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<{}>>
> = () => {
  //const newEmployee: UserAccount = {ID: "",FIRST_NAME: "",LAST_NAME: "",PIN: "", EMAIL_ADDR: "",ACTIVE: "", DEPARTMENT_ID: "", PERMISSIONS: "0",PHONE: "", SHIFT_ID: "", MANAGER: "", PREFRENCES: "NONE", SIGNITURE: ""}
  const [editEmployee, setEditEmployee] = useState<UserAccount | null>(null);
  const [addEmployee, setAddEmployee] = useState<boolean>(false); //add new set
  const [hideInactive, setHideInactive] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  //const PERMISSIONS = [0,1,2,3,4,5,6,7,8,9,10];

  //default mapping of columns, each item in the list correlates to a column in the table
  //the default column (see above) will be used for each cell, the header provided here will be used for each column header
  const defaultColumnOrder =
    customReports["EmployeeAccounts"]["default"].customColumnOrder;
  const defaultVisibility =
    customReports["EmployeeAccounts"]["default"].customVisibility;
  const defaultSortOrder =
    customReports["EmployeeAccounts"]["default"].customSortOrder;
  const defaultColumnSizes =
    customReports["EmployeeAccounts"]["default"].customColumnSizes;

  //states managed by the table
  const [columnOrder, setColumnOrder] =
    React.useState<columnOrderState>(defaultColumnOrder);
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>(defaultVisibility);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [columnResizeMode, setColumnResizeMode] =
    useState<ColumnResizeMode>("onChange");
  const [columnSizing, setColumnSizing] =
    useState<ColumnSizing>(defaultColumnSizes); //{ size: 20, minSize: 5, maxSize: Number.MAX_SAFE_INTEGER }
  const [sorting, setSorting] = React.useState<SortingState>(defaultSortOrder);
  const [grouping, setGrouping] = React.useState<GroupingState>([]);
  const [employees, setEmployees] = React.useState<UserAccount[]>([]);
  //styling variables and menu trackers
  const [openEditColumnMenu, setOpenEditColumnMenu] = useState<boolean>(false); //department selection menu state
  const [editMode, setEditMode] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState<boolean>(false);
  const [activeSort, setActiveSort] = useState<string>("Default"); //department selection menu state
  const [customFilter, setCustomFilter] = useState<{
    key: string;
    value: string;
  } | null>(null); //filter by status [0...x]
  const [openFilterMenu, setOpenFilterMenu] = useState<{
    keySet: string;
    defaultValue: string;
    optionList: string[];
  } | null>(null);

  const employeeAccountApi = new EmployeeAccountApi();

  const defaultColumns = [
    table.createDataColumn((row) => "DBR", {
      header: () => <div className="w-full h-full bg-white pt-3">Debug</div>,
      cell: (props) => (
        <li
          className={"text-left list-none"}
          onClick={() => console.log("row props", props)}
        >
          LOG
        </li>
      ),
      id: "DEBUG",
    }),
    table.createDataColumn("ID", {
      header: () => <div className="w-full h-full bg-white pt-3"> ID </div>,
    }),
    table.createDataColumn("FIRST_NAME", {
      header: () => (
        <div className="w-full h-full bg-white pt-3"> First Name </div>
      ),
    }),
    table.createDataColumn("LAST_NAME", {
      header: () => (
        <div className="w-full h-full bg-white pt-3"> Last Name </div>
      ),
    }),
    table.createDataColumn("DEPARTMENT_ID", {
      header: () => (
        <div className="w-full h-full bg-white pt-3"> Department </div>
      ),
    }),
    table.createDataColumn("SHIFT_ID", {
      header: () => <div className="w-full h-full bg-white pt-3"> Shift </div>,
    }),
    table.createDataColumn("MANAGER", {
      header: () => (
        <div className="w-full h-full bg-white pt-3"> Manager ID </div>
      ),
    }),
    table.createDataColumn("EMAIL_ADDR", {
      header: () => <div className="w-full h-full bg-white pt-3"> Email </div>,
    }),
    table.createDataColumn("PHONE", {
      header: () => <div className="w-full h-full bg-white pt-3"> Phone </div>,
    }),
    table.createDataColumn("PERMISSIONS", {
      header: () => (
        <div className="w-full h-full bg-white pt-3"> Permission Lvl </div>
      ),
    }),
    table.createDataColumn("ACTIVE", {
      header: () => <div className="w-full h-full bg-white pt-3"> Active </div>,
    }),
    table.createDataColumn("PIN", {
      header: () => <div className="w-full h-full bg-white pt-3"> Pin </div>,
    }),
    table.createDataColumn("PREFRENCES", {
      header: () => (
        <div className="w-full h-full bg-white pt-3"> Prefrences </div>
      ),
    }),
    table.createDataColumn("SIGNITURE", {
      header: () => (
        <div className="w-full h-full bg-white pt-3"> Signiture </div>
      ),
    }),
    table.createDataColumn((row) => "EDIT", {
      header: () => <div className="w-full h-full bg-white pt-3"> Edit </div>,
      cell: (props) => (
        <li
          className={
            "text-center items-center justify-center list-none flex flex-row space-x-2 text-green-500 hover:text-green-800 font-bold"
          }
          onClick={() => setEditEmployee(props.row.original)}
        >
          <p>EDIT</p>
          <RiPencilRuler2Line />{" "}
        </li>
      ),
      id: "EDIT",
    }),
  ];

  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);

  //gatther master info screed
  useEffect(() => {
    const fetchEmployees = async () => {
      const startTime = performance.now();
      //console.log("fetching WoFirm")
      let temp: UserAccount[] = await employeeAccountApi.get();
      if (temp) {
        setEmployees(temp);
      }
      const duration = performance.now() - startTime;
      console.log(`set Employee list took ${duration}ms`);
    };

    if (forceRefresh === false) {
      console.log(`Loading Employees`);
      fetchEmployees()
        .catch((e) => console.error("FetchEmployee error", e))
        .then(() => {
          console.log("done!");
          setForceRefresh(true);
        });
    }
  }, [forceRefresh]);

  const activeFilteredEmployees = useMemo(
    () =>
      hideInactive ? employees.filter((e) => e.ACTIVE === "Y") : employees,
    [hideInactive, employees]
  );

  const managerList = useMemo(
    () =>
      Array.from(new Set(activeFilteredEmployees.map((emp) => emp.MANAGER))),
    [activeFilteredEmployees]
  );

  const deptList = useMemo(
    () =>
      Array.from(
        new Set(activeFilteredEmployees.map((emp) => emp.DEPARTMENT_ID))
      ),
    [activeFilteredEmployees]
  );

  const shiftList = ["1ST", "2ND", "3RD"];

  const customFilteredEmployees = useMemo(
    () =>
      customFilter !== null
        ? activeFilteredEmployees.filter(
            (emp: UserAccount) => emp[customFilter.key] === customFilter.value
          )
        : activeFilteredEmployees,
    [activeFilteredEmployees, customFilter]
  );

  const data = useMemo(
    () =>
      search.length > 0
        ? customFilteredEmployees.filter((e) =>
            (e.FIRST_NAME + " " + e.LAST_NAME)
              .toUpperCase()
              .includes(search.toUpperCase())
          )
        : customFilteredEmployees,
    [customFilteredEmployees, search]
  );

  const MoveColLeft = (ind: number) => {
    if (ind === 0) return;
    let newOrd = columnOrder;
    let tmp = newOrd[ind - 1];
    newOrd[ind - 1] = newOrd[ind];
    newOrd[ind] = tmp;
    instance.setColumnOrder([...newOrd]); //must speread the arr/ have the [...ar]
  };
  const MoveColRight = (ind: number) => {
    if (ind === columnOrder.length) return;
    let newOrd = columnOrder;
    let tmp = newOrd[ind + 1];
    newOrd[ind + 1] = newOrd[ind];
    newOrd[ind] = tmp;
    instance.setColumnOrder([...newOrd]);
  };

  const BuildCustomSort = (newColKey: any) => {
    let newSort: { id: string; desc: boolean }[] = sorting;
    let currentColKeyVal = newSort.findIndex((x) => x.id === newColKey);
    if (currentColKeyVal === -1) {
      newSort.push({ id: newColKey, desc: true });
    } else {
      if (newSort[currentColKeyVal].desc === true) {
        newSort[currentColKeyVal] = { id: newColKey, desc: false };
      } else {
        newSort = newSort.filter((e) => e.id !== newColKey);
      }
    }
    setSorting([...newSort]);
    setActiveSort("Custom");
  };

  //update sorting on data change
  useEffect(() => {
    setSorting([...defaultSortOrder]);
  }, [data]);

  //the inialized useable table to be displayed
  const instance = useTableInstance(table, {
    data, //master info sorted into single dept
    columns, //use state holding the defaultColumns
    columnResizeMode, //enable resizing
    defaultColumn, //set the default column
    autoResetPagination: true,
    state: {
      //tell the table what variables you are actively tracking
      sorting,
      columnVisibility,
      pagination,
      columnOrder,
      //columnPinning,
      columnSizing,
      grouping,
    },
    initialState: {
      sorting,
      columnVisibility,
      columnOrder,
      columnSizing,
    },
    //setters, these pass a setter functions used to update the table states, you should have one for each state
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGroupingChange: setGrouping,
    onColumnOrderChange: setColumnOrder,
    onColumnSizingChange: setColumnSizing,
    onColumnVisibilityChange: setColumnVisibility,
    //onColumnPinningChange: setColumnPinning,
    //row models
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //meta allows you to pass functions to cells
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip age index reset until after next rerender
        console.log(rowIndex, columnId, value);
      },
    },
    //enable debugging, these can be taken out for production they track and report the sepped of various operations...
    //debugTable: true,
    //debugHeaders: true,
    //debugColumns: true,
    //debugRows: true,
  });

  return (
    <div className="flex flex-col w-full h-full">
      {/* slideover instatiations */}
      <EditEmployeeSlideover
        onClose={() => setEditEmployee(null)}
        onSubmit={(e) => {
          setForceRefresh(false);
          console.log("edit results: ", e);
        }}
        employee={editEmployee}
        allEmployees={employees}
      />
      <AddEmployeeSlideover
        onClose={() => setAddEmployee(false)}
        onSubmit={(e) => {
          setForceRefresh(false);
          console.log("Add results: ", e);
        }}
        adding={addEmployee}
      />
      {openFilterMenu !== null ? (
        <KeyValueListBox
          className={
            "relative p-1 w-1/4 items-center justify-center rounded-md shadow overflow-y-auto "
          }
          isOpen={openFilterMenu !== null}
          onClose={() => setOpenFilterMenu(null)}
          keyset={openFilterMenu.keySet}
          value={customFilter}
          defaultValue={openFilterMenu.defaultValue}
          setValue={setCustomFilter}
          optionList={openFilterMenu.optionList}
        />
      ) : null}
      <div className="relative flex flex-col px-2 text-slate-900 h-full">
        {/*wrapper*/}
        <div className="flex flex-row w-full py-2 space-x-2 justify-between">
          {/* top menu */}

          {/* sorts and filtrers */}
          <div className="flex flex-row w-full space-x-2 overflow-x-auto overflow-y-hidden">
            {/* actions */}
            <div className="flex flex-col w-fit divide-y">
              <label className="flex flex-row w-full font-semibold justify-center text-center bg-white rounded-t-md px-2">
                Actions:
              </label>
              <div className="flex flex-row w-full overflow-hidden p-1 bg-white rounded-b-md space-x-2">
                <button
                  disabled={true}
                  className={
                    "p-1 w-1/2 grow items-center justify-center rounded-md shadow flex flex-row space-x-2 border bg-green-500 text-white hover:bg-green-800 truncate"
                  }
                  onClick={() => setAddEmployee(true)}
                >
                  <p>Add New Employee</p>
                  <HiPlusCircle size={20} />
                </button>
                <input
                  id="Search"
                  type="text"
                  autoComplete="false"
                  className="form-input block sm:text-sm sm:leading-5 p-1 rounded-md border shadow"
                  placeholder={"search by name"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                <button
                  onClick={() => setSearch("")}
                  className={
                    "grow w-auto items-center justify-center hover:text-red-500"
                  }
                >
                  <BiX className="mr-1" size={28} />
                </button>
                {/* <BiSearchAlt2 onClick={() => setSearch({search:search.temp, temp:""})} className="hover:text-sky-500" size={28}/> */}
              </div>
            </div>

            {/* filters */}
            <div className="flex flex-col w-fit grow divide-y">
              <label className="flex flex-row w-full font-semibold justify-center text-center bg-white rounded-t-md px-2">
                Filters:
              </label>
              <div className="flex flex-row w-full overflow-hidden p-1 bg-white rounded-b-md space-x-2">
                <button
                  className={clsx(
                    "p-1 w-1/4 items-center justify-center rounded-md shadow truncate border",
                    hideInactive === true
                      ? "bg-white hover:bg-sky-200"
                      : "bg-sky-500 hover:bg-sky-200 text-white"
                  )}
                  onClick={() => setHideInactive(!hideInactive)}
                >
                  Inactive
                </button>

                <button
                  className={clsx(
                    "p-1 w-1/4 items-center justify-center rounded-md shadow truncate border",
                    customFilter && customFilter.key === "DEPARTMENT_ID"
                      ? "bg-sky-500 hover:bg-sky-200 text-white"
                      : "bg-white hover:bg-sky-200"
                  )}
                  onClick={() =>
                    setOpenFilterMenu({
                      keySet: "DEPARTMENT_ID",
                      defaultValue: "Department",
                      optionList: deptList,
                    })
                  }
                >
                  {customFilter === null ||
                  customFilter.key !== "DEPARTMENT_ID" ? (
                    <p>Department</p>
                  ) : (
                    <p>{customFilter.value}</p>
                  )}
                </button>

                <button
                  className={clsx(
                    "p-1 w-1/4 items-center justify-center rounded-md shadow truncate border",
                    customFilter && customFilter.key === "SHIFT_ID"
                      ? "bg-sky-500 hover:bg-sky-200 text-white"
                      : "bg-white hover:bg-sky-200"
                  )}
                  onClick={() =>
                    setOpenFilterMenu({
                      keySet: "SHIFT_ID",
                      defaultValue: "Shift",
                      optionList: shiftList,
                    })
                  }
                >
                  {customFilter === null || customFilter.key !== "SHIFT_ID" ? (
                    <p>Shift</p>
                  ) : (
                    <p>{customFilter.value}</p>
                  )}
                </button>

                <button
                  className={clsx(
                    "p-1 w-1/4 items-center justify-center rounded-md shadow truncate border",
                    customFilter && customFilter.key === "MANAGER"
                      ? "bg-sky-500 hover:bg-sky-200 text-white"
                      : "bg-white hover:bg-sky-200"
                  )}
                  onClick={() =>
                    setOpenFilterMenu({
                      keySet: "MANAGER",
                      defaultValue: "Manager",
                      optionList: managerList,
                    })
                  }
                >
                  {customFilter === null || customFilter.key !== "MANAGER" ? (
                    <p>Manager</p>
                  ) : (
                    <p>{customFilter.value}</p>
                  )}
                </button>

                <button
                  onClick={() => {
                    setCustomFilter(null);
                    setOpenFilterMenu(null);
                  }}
                  className={
                    "grow w-auto items-center justify-center hover:text-red-500"
                  }
                >
                  <BiX className="mr-1" size={28} />
                </button>
              </div>
            </div>

            {/* sorts */}
            <div className="flex flex-col w-fit grow divide-y">
              <label className="flex flex-row w-full font-semibold justify-center text-center bg-white rounded-t-md px-2">
                Sorting:
              </label>
              <div className="flex flex-row w-full overflow-hidden p-1 bg-white rounded-b-md space-x-2">
                <button
                  className={clsx(
                    "p-1 w-1/2 items-center justify-center rounded-md shadow truncate border",
                    activeSort === "Default"
                      ? "bg-green-500 hover:bg-green-200 text-white"
                      : "bg-white hover:bg-green-200"
                  )}
                  onClick={() => {
                    setSorting([...defaultSortOrder]);
                    setActiveSort("Default");
                  }}
                >
                  Default
                </button>

                <button
                  className={clsx(
                    "p-1 w-1/2 items-center justify-center rounded-md shadow truncate border",
                    activeSort === "Custom"
                      ? "bg-green-500 hover:bg-green-200 text-white"
                      : "bg-white hover:bg-green-200"
                  )}
                  onClick={() => {
                    setSorting([]);
                    setActiveSort("Custom");
                  }}
                >
                  Custom Sort
                </button>
              </div>
            </div>
          </div>

          {/* edit options selection */}
          <div className="flex flex-col divide-y">
            <label className="flex flex-row w-full font-semibold justify-center text-center bg-white rounded-t-md">
              Options:
            </label>
            <div className="flex flex-row divide-x shadow font-semibold">
              <button
                disabled={!forceRefresh}
                className={
                  "bg-white p-2 grow w-auto items-center justify-center hover:text-red-500 rounded-bl-md "
                }
                onClick={() =>
                  forceRefresh === true ? setForceRefresh(false) : null
                }
              >
                <BiRefresh size={24} />
              </button>
              <button
                className={
                  "bg-white p-2 grow w-auto items-center justify-center " +
                  (editMode
                    ? " text-sky-500 hover:text-black"
                    : "hover:text-sky-500 ")
                }
                onClick={() => setEditMode(!editMode)}
              >
                {editMode ? (
                  <RiPencilRuler2Line size={20} />
                ) : (
                  <RiPencilRuler2Line size={20} />
                )}
              </button>
              <button
                disabled={!editMode}
                className={
                  "bg-white p-2 grow w-auto items-center justify-center rounded-br-md " +
                  (editMode ? "hover:text-green-500 bg-white" : "bg-slate-200")
                }
                onClick={() => setOpenEditColumnMenu(!openEditColumnMenu)}
              >
                <BiColumns size={20} />
              </button>
            </div>
          </div>
          {/*end order selection */}
        </div>
        {/*end top menu bar*/}
        {/* Column options Modal */}
        {openEditColumnMenu ? (
          <div
            onClick={(e) => setOpenEditColumnMenu(false)}
            className="fixed z-20 inset-0 bg-gray-600 bg-opacity-50 h-full w-full"
            id="my-modal"
          />
        ) : (
          <></>
        )}
        {openEditColumnMenu ? (
          <div className="absolute z-30 right-0 top-0 flex flex-col w-1/6 bg-white rounded-md shadow mr-2 mt-2 text-left overflow-y-auto scrollbar-hide divide-y">
            <div
              key={-1}
              className="px-1 sticky top-0 shadow"
              onClick={() => setOpenEditColumnMenu(!openEditColumnMenu)}
            >
              <label className="text-center font-semibold flex flex-row items-center">
                <FiX size={24} /> Close Edit Column menu
              </label>
            </div>

            <div key={0} className="px-1">
              <label>
                <input
                  type="checkbox"
                  checked={instance.getIsAllColumnsVisible()}
                  onChange={instance.getToggleAllColumnsVisibilityHandler()}
                />{" "}
                Toggle All
              </label>
            </div>
            {instance.getAllLeafColumns().map((column, i) => {
              return (
                <div
                  key={column.id}
                  className="px-1 flex flex-row justify-between space-x-3 even:bg-white odd:bg-slate-100"
                >
                  <label>
                    <input
                      {...{
                        type: "checkbox",
                        checked: column.getIsVisible(),
                        onChange: column.getToggleVisibilityHandler(),
                      }}
                    />{" "}
                    {column.id}
                  </label>
                  <div className="flex flex-row space-x-2">
                    <BiArrowToLeft
                      className="mt-1 cursor-pointer hover:text-green-500"
                      onClick={() => MoveColLeft(i)}
                    />
                    <BiArrowToRight
                      className="mt-1 cursor-pointer hover:text-green-500"
                      onClick={() => MoveColRight(i)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
        {/*end column options modal  */}

        {/* main table view */}
        <div className="flex flex-col grow max-w-full overflow-y-auto scroll-smooth bg-white rounded-t-md text-sm">
          <RTStyles>
            {/* activate custom css for the enclosed section, this fixes sticky headers*/}
            <table className="table sticky w-full">
              <thead className={"header"}>
                {/*table headers */}
                {instance.getHeaderGroups().map((headerGroup) => (
                  <tr className="tr bg-white" key={headerGroup.id}>
                    {headerGroup.headers.map((header, hgi) => (
                      //row wrapper
                      <th
                        className="th relative leading-tight align-text-bottom"
                        {...{
                          key: `HG${hgi}`,
                          colSpan: header.colSpan,
                          style: {
                            width: header.getSize(),
                          },
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          // sorting wrapper/ action wrapper
                          <div className="">
                            <div
                              className={
                                "absolute inset-x-0 top-0 flex flex-row px-1"
                              }
                            >
                              {/* absolute/relative buttons, added into the headers, be carefull not to override the sizing */}
                              {/* groupable add-on */}
                              {header.column.getCanGroup() ? (
                                header.column.getIsGrouped() ? (
                                  <div
                                    className="flex flex-row text-red-500 cursor-pointer select-none justify-center items-center"
                                    onClick={header.column.getToggleGroupingHandler()}
                                  >
                                    <FaLayerGroup />
                                    {`(${header.column.getGroupedIndex()})`}
                                  </div>
                                ) : (
                                  <div
                                    className="flex flex-row text-green-500 cursor-pointer select-none justify-center items-center"
                                    onClick={header.column.getToggleGroupingHandler()}
                                  >
                                    <FaLayerGroup />+
                                  </div>
                                )
                              ) : null}
                              {/* sortable add-on */}
                              {header.column.getCanSort() ? (
                                header.column.getIsSorted() === "asc" ? (
                                  <div
                                    className="flex flex-row text-sky-500 cursor-pointer select-none justify-center items-center"
                                    onClick={
                                      () =>
                                        BuildCustomSort(
                                          header.id
                                        ) /*header.column.getToggleSortingHandler()*/
                                    }
                                  >
                                    <FaAngleDoubleUp />
                                    {sorting.findIndex(
                                      (e) => e.id === header.id
                                    ) + 1}
                                  </div>
                                ) : header.column.getIsSorted() === "desc" ? (
                                  <div
                                    className="flex flex-row text-red-500 cursor-pointer select-none justify-center items-center"
                                    onClick={
                                      () =>
                                        BuildCustomSort(
                                          header.id
                                        ) /*header.column.getToggleSortingHandler()*/
                                    }
                                  >
                                    <FaAngleDoubleDown />
                                    {sorting.findIndex(
                                      (e) => e.id === header.id
                                    ) + 1}
                                  </div>
                                ) : (
                                  <div
                                    className="flex flex-row text-amber-500 cursor-pointer select-none justify-center items-center"
                                    onClick={
                                      () =>
                                        BuildCustomSort(
                                          header.id
                                        ) /*header.column.getToggleSortingHandler()*/
                                    }
                                  >
                                    <FaAngleRight />
                                  </div>
                                )
                              ) : null}
                            </div>
                            {/*render cell header*/}
                            {header.renderHeader()}
                          </div>
                        )}
                        {/* resizing bar */}
                        {editMode ? (
                          <div
                            {...{
                              onMouseDown: header.getResizeHandler(),
                              onTouchStart: header.getResizeHandler(),
                              className: `resizer ${
                                header.column.getIsResizing()
                                  ? "isResizing"
                                  : ""
                              }`,
                              style: {
                                transform:
                                  columnResizeMode === "onEnd" &&
                                  header.column.getIsResizing()
                                    ? `translateX(${
                                        instance.getState().columnSizingInfo
                                          .deltaOffset
                                      }px)`
                                    : "",
                              },
                            }}
                          />
                        ) : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y body">
                {/*cells/ main table body*/}
                {instance.getRowModel().rows.map((row, i) => (
                  <tr
                    className={
                      "divide-x tr " +
                      (i % 2 === 0
                        ? "bg-slate-100 hover:bg-slate-200"
                        : "bg-white hover:bg-slate-200")
                    }
                    {...{
                      key: `irmr-${i}}`,
                      // style: getRowStyles(row, i)
                    }}
                  >
                    {row.getVisibleCells().map((cell, rgv) => (
                      <td
                        className="td leading-tight align-top"
                        {...{
                          key: `rgv-${rgv}`,
                          style: getCellStyles(cell), //add otherwise default
                        }}
                      >
                        {cell.getIsGrouped() ? (
                          // If it's a grouped cell, add an expander and row count
                          <>
                            <button
                              {...{
                                onClick: row.getToggleExpandedHandler(),
                                style: {
                                  cursor: row.getCanExpand()
                                    ? "pointer"
                                    : "normal",
                                },
                              }}
                            >
                              {row.getIsExpanded() ? "👇" : "👉"}{" "}
                              {cell.renderCell()} ({row.subRows.length})
                            </button>
                          </>
                        ) : cell.getIsAggregated() ? // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        //cell.renderAggregatedCell()
                        null : cell.getIsPlaceholder() ? (
                          cell.renderCell() //null For cells with repeated values, render null
                        ) : (
                          // Otherwise, just render the regular cell
                          cell.renderCell()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </RTStyles>
        </div>
        {/* table controls */}
        <div className="sticky bottom-0 flex flex-row justify-center gap-2 bg-white border-t py-2 mb-2 rounded-b-md">
          <button
            className="border rounded px-1"
            onClick={() => instance.setPageIndex(0)}
            disabled={!instance.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            className="border rounded px-1"
            onClick={() => instance.previousPage()}
            disabled={!instance.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            className="border rounded px-1"
            onClick={() => instance.nextPage()}
            disabled={!instance.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            className="border rounded px-1"
            onClick={() => instance.setPageIndex(instance.getPageCount() - 1)}
            disabled={!instance.getCanNextPage()}
          >
            {">>"}
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {instance.getState().pagination.pageIndex + 1} of{" "}
              {instance.getPageCount()}
            </strong>
          </span>
          <span className="flex items-center gap-1">
            | Go to page:
            <input
              type="number"
              defaultValue={instance.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                instance.setPageIndex(page);
              }}
              className="border px-1 rounded w-16"
            />
          </span>
          <select
            value={instance.getState().pagination.pageSize}
            onChange={(e) => {
              instance.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        {/* end table controls */}
      </div>
    </div>
  );
};

export default Employees;
