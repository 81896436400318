import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  KeyboardEvent,
  createContext,
  useCallback,
  useContext,
} from "react";
import axios from "axios";
import ParentApi from "../../utilites/Api/ParentApi";
import clsx from "clsx";
import { useRecoilState } from "recoil";
import { ReactUserAccount } from "../../utilites/Globals/Atoms";
import RouterUtility from "../../utilites/routerUtils";
import {
  BiPlus,
  BiMinus,
  BiMessageAdd,
  BiMessageAltAdd,
  BiMessageAltX,
  BiMessageAltCheck,
  BiMessageAltDots,
  BiTrash,
  BiSave,
  BiX,
} from "react-icons/bi";
import { object } from "prop-types";
import {
  getColorConverter,
  colorLookUp,
  reverseLookup,
} from "../../utilites/Decoders/ColorConverter";
import { fireDb, fireStore } from "../../utilites/firebase";
import { onValue, ref } from "firebase/database";
import { ref as storeRef, getDownloadURL, getBlob } from "firebase/storage";
import { useAuth } from "../../utilites/Authprovider";
import { DocControl_Standard_Decoder, defaultDrop } from "../../utilites/Decoders/Decoders";
import { FaArrowDown, FaArrowUp, FaXbox } from "react-icons/fa";
import sortBy from "sort-by";
import AddDropBoxSlideover from "../../utilites/slideOvers/AddDropBoxSlideover";

const defaultUrlParamKeys = {
  isValid: "false",
  userLocation: "main",
};

const DropBox: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<{
      fullScreenIn?: boolean;
    }>
  >
> = ({ fullScreenIn }) => {
  const DocTypes = ["DropBox"];
  const routerUtility = new RouterUtility();
  const [sourcePath, setSourcePath] = useState<string>(
    routerUtility.urlParams["Category"] === ":Category"
      ? DocTypes[0]
      : routerUtility.urlParams["Category"]
  );
  const [docList, setDocList] = useState<DocControl_Standard_Decoder[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [defaultSort, setDefaultSort] = useState<string>("Doc_Num");
  const [addDoc, setAddDoc] = useState<DocControl_Standard_Decoder|null>(null);

  useEffect(() => {
    //fetch content combines the blogposts with their associated images
    const FetchContent = async (temp: DocControl_Standard_Decoder[]) => {
      try {
        {
          //pull in files
          // for (var x = 0; x < temp.length; x++) {
          //   if (typeof temp[x].content === "string") {
          //     const retrievedContent = await getDownloadURL(
          //       storeRef(fireStore, temp[x].content as string)
          //     );
          //     temp[x].content = retrievedContent;
          //   }
          // }
        }

        //console.log(`recived: ${sourcePath}`, temp);
        setDocList(temp);
      } catch (e) {
        console.log("fetch blog post content failed!", e);
      }
    };

    if (!DocTypes.includes(sourcePath)) {
      console.log("invalid source", sourcePath);
      return;
    }

    const connectionPath = ref(fireDb, sourcePath);
    return onValue(connectionPath, (snapshot) => {
      const data = snapshot.val();
      console.log("raw res", data);
      if (snapshot.exists()) {
        let temp = Object.values(data).map(
          (x) => x as DocControl_Standard_Decoder
        );
        if (temp.length > 0) {
          FetchContent(temp);
        }
      }
    });
  }, [sourcePath]);

  const changeDocTypes = (key: string) => {
    if (!DocTypes.includes(sourcePath)) {
      console.log("invalid source", sourcePath);
      return;
    }

    setSourcePath(key);
    routerUtility.linkTo(null, `/DropBox/${key}`);
  };

  const downloadDocument = async (x: DocControl_Standard_Decoder) => {
    if (!DocTypes.includes(sourcePath)) {
      console.log("invalid source", sourcePath);
      return;
    }

    try {
      const blob = await getBlob(
        storeRef(fireStore, `${sourcePath}Files/${x.File_Path}`)
      );

      // Create a blob URL and initiate download
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = `${x.Doc_Name}.pdf`; // Set the download file name
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Clean up the blob URL after download
      URL.revokeObjectURL(blobUrl);
      return;
    } catch (error) {
      console.error("Error fetching document:", error);
      return;
    }
  };

  const getAttachedDoc = async (x: DocControl_Standard_Decoder) => {
    if (!DocTypes.includes(sourcePath)) {
      console.log("invalid source", sourcePath);
      return;
    }

    const mode = "pdf";
    const retrievedContent = await getDownloadURL(
      storeRef(fireStore, `${sourcePath}Files/${x.File_Path}`)
    );

    const tempDoc = retrievedContent;
    switch (mode) {
      // case "preview":
      //     console.log(response);
      //     const bText = response.data;
      //     setExportedDocument(bText);
      //     updateUrl("userLocation", "exporting");
      //     break;
      // case "xlsx":
      //     // create "a" HTML element with href to file & click
      //     const href = URL.createObjectURL(response.data);
      //     const link = document.createElement('a');
      //     link.href = href;
      //     //link.setAttribute('download', 'file.xlsx'); //or any other extension
      //     link.setAttribute('download', 'file.html'); //or any other extension
      //     document.body.appendChild(link);
      //     link.click();

      //     // clean up "a" element & remove ObjectURL
      //     document.body.removeChild(link);
      //     URL.revokeObjectURL(href);
      //     break;
      case "pdf":
        const href = retrievedContent;
        window.open(href, "_blank");
        break;
      default:
        break;
    }
  };

  const abreivateNotes = (x: string) => {
    if (!x) {
      return "NA";
    }

    const ti = x.indexOf("Title");
    const sc = x.indexOf("Scope");

    if (sc !== -1) {
      if (ti !== -1) {
        return x.substring(ti + 6, sc)?.trim();
      } else {
        return x.substring(sc + 6)?.trim();
      }
    } else {
      return x;
    }
  };

  const useDocs = useMemo(() => {
    if (searchString === "") {
      return docList.sort(sortBy(defaultSort));
    } else {
      return docList.sort((a, b) => {
        const ja = JSON.stringify(a).toLocaleLowerCase();
        const jb = JSON.stringify(b).toLocaleLowerCase();
        if (ja.includes(searchString.toLocaleLowerCase())) {
          return jb.includes(searchString.toLocaleLowerCase()) ? 0 : -1;
        } else {
          return 1;
        }
      });
    }
  }, [docList, searchString, defaultSort]);

  return (
    <div
      className={clsx(
        "relative flex w-full divide-black flex-col overflow-hidden h-screen bg-slate-800"
      )}
    >
      <AddDropBoxSlideover onClose={() => setAddDoc(null)} onSubmit={() => setAddDoc(null)} isOpen={addDoc} sourcePath={sourcePath}/>
      {/* blurring */}

      {/*main window*/}
      <div className={clsx("flex flex-col relative overflow-hidden w-full")}>
        {/* top row controls */}
        <div
          className={clsx(
            "flex flex-row sticky top-0 flex-none divide-x divide-black bg-slate-800 overflow-hidden font-semibold border-b border-black px-2.5"
          )}
        >
          <div
            className={clsx(
              "flex flex-row flex-none divide-x divide-black oveflow-hidden"
            )}
          >
            {DocTypes.map((x) => (
              <button
                key={x}
                className={clsx(
                  `py-1 px-2 flex flex-row space-x-1`,
                  sourcePath === x
                    ? "bg-green-300 text-green-800 hover:bg-green-500"
                    : "bg-slate-500 hover:bg-slate-300 text-slate-800"
                )}
                onClick={() => changeDocTypes(x)}
              >
                {x}
              </button>
            ))}

            <button
              className={clsx(
                "py-1 px-2 flex flex-row space-x-1 bg-green-300 text-green-800 hover:bg-green-500"
              )}
              onClick={() => setAddDoc(defaultDrop)}
            >
              Add Document
            </button>
          </div>

          <div className="flex-1">
            <p className="invisible">spacer</p>
          </div>

          <div className="flex flex-row space-x-2 p-0.5 overflow-hidden flex-none">
            <input
              type="text"
              className="sm:flex w-72 text-left px-2 shadow-md rounded focus:outline-none"
              value={searchString}
              placeholder="Search by keyword"
              onChange={(e) => setSearchString(e.target.value)}
            />

            <button
              onClick={(e) => setSearchString("")}
              className={clsx(
                "flex flex-none px-0.5 border rounded items-center shadow",
                searchString !== ""
                  ? "bg-red-300 text-red-800 border-red-800 hover:bg-red-500"
                  : "text-white border-white"
              )}
            >
              <BiX size={24} className="text-white" />
            </button>
          </div>
        </div>

        <div
          className={"relative flex flex-col overflow-y-auto scrollbar-hide"}
        >
          {/* header row */}
          <div className="sticky top-0 mb-1 px-4 flex flex-row space-x-2 items-center align-center shadow-md flex-none overflow-hidden bg-slate-700 h-8 text-slate-300 text-xs font-medium py-1 border-b border-sky-800">
            <label
              className={clsx(
                "flex-none text-left w-1/4 overflow-hidden truncate text-lg font-semibold cursor-pointer",
                defaultSort === "Doc_Name" ? "text-green-500" : ""
              )}
              onClick={() => {
                setDefaultSort("Doc_Name");
                setSearchString("");
              }}
            >
              Name
            </label>

            <p
              className={clsx(
                "flex-none text-left w-24 overflow-hidden truncate cursor-pointer",
                defaultSort === "Doc_Num" ? "text-green-500" : ""
              )}
              onClick={() => {
                setDefaultSort("Doc_Num");
                setSearchString("");
              }}
            >
              Doc#
            </p>

            <p
              className={clsx(
                "flex-none text-left w-12 overflow-hidden truncate cursor-pointer",
                defaultSort === "Revision" ? "text-green-500" : ""
              )}
              onClick={() => {
                setDefaultSort("Revision");
                setSearchString("");
              }}
            >
              Rev
            </p>

            <p
              className={clsx(
                "flex-none text-left w-12 overflow-hidden truncate cursor-pointer hidden",
                defaultSort === "Doc_Name" ? "text-green-500" : ""
              )}
              onClick={() => {
                setDefaultSort("Doc_Num");
                setSearchString("");
              }}
            >
              {/* {new Date(x.Release_Date).toLocaleDateString()} */}
              Cont.
            </p>

            <p
              className={clsx(
                "flex-1 text-left overflow-hidden truncate ellipsis",
                defaultSort === "Notes" ? "text-green-500" : ""
              )}
              onClick={() => {
                setDefaultSort("Notes");
                setSearchString("");
              }}
            >
              Description
            </p>

            {/* <p className="bg-gray-400 hover:bg-gray-800 hover:text-white px-2 h-full w-4 flex-none overflow-hidden rounded-md border border-gray-300 shadow-md">
              controls
            </p> */}
          </div>

          {/* doc rows */}
          {useDocs.map((x, i) => (
            <div
              key={x.Doc_ID}
              className={clsx(
                "mx-2 flex flex-row space-x-2 items-center align-center shadow-md flex-none overflow-hidden h-8 text-slate-300 text-xs font-medium py-1 px-2 border-b border-sky-800",
                x.File_Path.includes("ERROR") ? "bg-red-950" : "bg-slate-700"
              )}
            >
              <label className="flex-none text-left w-1/4 overflow-hidden truncate text-lg font-semibold">
                {x.Doc_Name}
              </label>

              <p className="flex-none text-left w-24 overflow-hidden truncate">
                {x.Doc_Num}
              </p>

              <p className="flex-none text-left w-12 overflow-hidden truncate">
                {x.Revision}
              </p>

              <p className="flex-none text-left w-12 overflow-hidden truncate text-slate-500 hidden">
                {/* {new Date(x.Release_Date).toLocaleDateString()} */}
                TBD
              </p>

              <p
                className={clsx(
                  "flex-1 text-left overflow-hidden truncate ellipsis",
                  !x.Notes ? "text-slate-500" : ""
                )}
              >
                {abreivateNotes(x.Notes)}
              </p>

              <button
                disabled={x.File_Path.includes("ERROR")}
                className={clsx(
                  "px-2 h-full w-12 flex-none overflow-hidden rounded-md border shadow-md",
                  x.File_Path.includes("ERROR")
                    ? "bg-red-800 hover:bg-red-500 hover:text-red-800 text-red-300 border-red-800"
                    : "bg-sky-800 hover:bg-sky-500 hover:text-sky-800 text-sky-300 border-sky-800"
                )}
                onClick={() => getAttachedDoc(x)}
              >
                Open
              </button>
              <button
                disabled={x.File_Path.includes("ERROR")}
                className={clsx(
                  "px-2 h-full w-24 flex-none overflow-hidden rounded-md border shadow-md",
                  x.File_Path.includes("ERROR")
                    ? "bg-red-800 hover:bg-red-500 hover:text-red-800 text-red-300 border-red-800"
                    : "bg-sky-800 hover:bg-sky-500 hover:text-sky-800 text-sky-300 border-sky-800"
                )}
                onClick={() => downloadDocument(x)}
              >
                Download
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropBox;
