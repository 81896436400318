import axios from "axios";
import {connectionStringBase} from "../Globals/Globals";
import { UserAccount } from "../Decoders/Decoders";
// import { useAlert } from "react-alert";
class EmployeeAccountApi {
  // private alert = useAlert();
  get = async () => {
    let connectionstring = connectionStringBase + "EmployeeAccount";
      return axios.get(connectionstring, { withCredentials: true }).then((res) => {
        if (res.status === 200) {
          // this.alert.show(`get successfull!`, {type:"success"});
          return res.data;
        } else {
          // this.alert.show(`employeeAccount get failed: [${res.status}]`);
          console.log("response status = " + res.status);
          return [];
        }
      });
    // }
  };

  put = async (UpdatedObject: UserAccount) => {
    let connectionstring = connectionStringBase + "ReactUserAccounts";
    let dateStr = Date.now().toLocaleString().substr(0,10);
    let details = {ID: UpdatedObject.ID, PIN:UpdatedObject.PIN, PERMISSIONS:UpdatedObject.PERMISSIONS, PREFRENCES:UpdatedObject.PREFRENCES, LASTUPDATE:dateStr }
    console.log("details:", details);
    console.log("updating user account:");
    return await axios
      .put(connectionstring, details, { withCredentials: true })
      .then((res) => {
        if (res.status === 204) {
          return res.status;
        } else {
          console.log("put response status = " + res.status);
          return null;
        }
      });
    
  };

  login = async (namePass: {ID:string, PIN:string}) => {
    let connectionstring = connectionStringBase + "EmployeeAccount";
    console.log("loging in with: ", namePass);
    return await axios
      .post(connectionstring, namePass, { withCredentials: true })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          console.log("put response status = " + res.status);
          return null;
        }
      });
  }

  // arrayBufferToBase64 = ( buffer ) => {
  //   var binary = '';
  //   var bytes = new Uint8Array( buffer );
  //   var len = bytes.byteLength;
  //   for (var i = 0; i < len; i++) {
  //       binary += String.fromCharCode( bytes[ i ] );
  //   }
  //   return window.btoa( binary );
  // }


  getSigniture = async (namePass: {ID:string, PIN:string}) => {
    let connectionstring = connectionStringBase + "EmployeeSigniture";
    console.log("Collecting Signiture for: ", namePass);
    return await axios
      .post(connectionstring, namePass, { withCredentials: true, responseType: 'arraybuffer'})
      .then((res) => {
        console.log(res.data);
        var arrayBufferView = new Uint8Array( res.data );
        var blob = new Blob( [ arrayBufferView ], { type: "image/jpeg" } );
        //var urlCreator = window.URL;
        //var imageUrl = urlCreator.createObjectURL(blob);
        return blob;
      })
  }

};
export default EmployeeAccountApi;

