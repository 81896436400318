import Slideover from "../slideOvers/Slideover"
import { useState, useEffect} from "react";
import React, {useRef} from "react";
import clsx from "clsx";
import {
  UserAccount,
  DocControl_Standard_Decoder,
  defaultDrop,
} from "../Decoders/Decoders";
import { VscAdd, VscBug, VscClose } from "react-icons/vsc";
import {BsDoorClosed, BsX} from "react-icons/bs";
import { useAuth } from "../Authprovider";
import { fireDb, fireStore } from "../firebase";
import { ref as storeRef, uploadBytes, UploadResult } from "firebase/storage";
import { onValue, ref, getDatabase, set, push } from "firebase/database";
import { createRoot } from "react-dom/client";
import RouterUtility from "../routerUtils";

export const NewFileDropForm = ({
  inDropFile,
  onClose,
  onSubmit,
  sourcePath
}: {
  inDropFile: DocControl_Standard_Decoder;
  onClose: () => void;
  onSubmit: () => void | null;
  sourcePath?: string | null;
}) => {
  const [openNodes, setOpenNodes] = useState<{
    profileDetails: boolean;
    notifications: boolean;
    prefrences: boolean;
    debug: boolean;
  }>({
    profileDetails: false,
    notifications: true,
    prefrences: false,
    debug: false,
  });

  const { user } = useAuth();
  const currentReactAccount = user;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [newFileDrop, setNewFileDrop] = useState<DocControl_Standard_Decoder>(inDropFile);
  const [newFileContent, setNewFileContent] = useState<File|null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const routerUtility = new RouterUtility();

  //save the new image to firebase
  async function uploadFile(image: File, fileName: string) {
    if (user) {
      try{
        //if user is logged in
        var storageRef = storeRef(
          fireStore,
          `${sourcePath}Files/${fileName}`
        );
        // Create a reference to the location in the storage bucket
        const uploadedImg = await uploadBytes(storageRef, image)
          .then((snapshot) => {
            console.log("Uploaded a blob or file!", snapshot);
            return snapshot;
          })
          .catch((e) => {
            console.log("upload image failed");
            return null;
          });
        return uploadedImg;
      }catch(e){
        console.log("upload failed...", e);
      }
    }

    console.log("no user...")
    return;
    
  }

  //save blogpost to firebase, converts content to storage path via upload img
  async function postToFireBase(newDropHeader: DocControl_Standard_Decoder) {
    setLoading(true);

    //upload associated image / file
    if (newFileContent instanceof File) {
      let contentStored = await uploadFile(newFileContent, newDropHeader.File_Path)
        .then((snapshot) => {
          console.log("upload blog post content complete!", snapshot);
          newDropHeader.File_Path = snapshot?.ref.fullPath
            ? snapshot.ref.fullPath
            : "err"; //change new post content from file to file path
          return snapshot;
        })
        .catch((e) => {
          console.log("upload blog post content failed", e);
          return null;
        });

      if (!contentStored) {
        console.log("failed to store content, opting out");
        setLoading(false); //update for better response display
        return null;
      }
    }

    try {
      //push new key
      var fbPostUid = push(ref(fireDb, sourcePath)).key; //get uuid from firebase
      newDropHeader.Reference = fbPostUid;
      //set key value
      set(ref(fireDb, `${sourcePath? sourcePath: "dropBox"}/${fbPostUid}`), newDropHeader) //save new post under uuid
        .then((e) => {
          console.log("post to firebase Success!", e);
          setLoading(false);
        })
        .catch((e) => {
          console.log("post to firebse fail!", e);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log("post to firebase Failed 2", e);
    }
  }

  return (
    <div className="h-full overflow-hidden">
      <div className="flex flex-col h-full w-full bg-gray-800 p-2 overflow-hidden justify-between">
        <div className="flex flex-col h-full w-full space-y-2">
          {/* title */}
          <div className="w-full">
            <label
              htmlFor="Doc name"
              className={clsx(
                "block text-sm font-medium leading-5 flex flex-row",
                newFileDrop.Doc_Name !== "" &&
                  newFileDrop.Doc_Name !== defaultDrop.Doc_Name
                  ? "text-green-500"
                  : "text-red-500"
              )}
            >
              Doc Name:
            </label>
            <div className="relative rounded-md shadow-sm">
              <input
                id="doc_name"
                type="text"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                value={newFileDrop.Doc_Name}
                onChange={(e) =>
                  setNewFileDrop({ ...newFileDrop, Doc_Name: e.target.value })
                }
              />
            </div>
          </div>

          {/* Author */}
          <div className="w-full">
            <label
              htmlFor="Doc num"
              className={clsx(
                "block text-sm font-medium leading-5 flex flex-row",
                newFileDrop.Doc_Num !== "" &&
                  newFileDrop.Doc_Num !== defaultDrop.Doc_Num
                  ? "text-green-500"
                  : "text-red-500"
              )}
            >
              Doc Num:
            </label>
            <div className="relative rounded-md shadow-sm">
              <input
                id="Doc Num"
                type="text"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                value={newFileDrop.Doc_Num}
                onChange={(e) =>
                  setNewFileDrop({ ...newFileDrop, Doc_Num: e.target.value })
                }
              />
            </div>
          </div>

          {/* content */}
          <div className="w-full">
            <label
              htmlFor="New File"
              className={clsx(
                "block text-sm font-medium leading-5 flex flex-row",
                newFileContent !== null ? "text-green-500"
                  : "text-red-500"
              )}
            >
              File Drop:
            </label>
            <div className="relative rounded-md shadow-sm text-white font-medium">
              <input
                id="New file"
                type="file"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                onChange={(e) =>
                  e.target.files
                    ? setNewFileContent(e.target.files[0])
                    : null
                }
              />
            </div>
          </div>

          {/* Author */}
          <div className="w-full">
            <label
              htmlFor="Notes"
              className={clsx(
                "block text-sm font-medium leading-5 flex flex-row",
                newFileDrop.Notes !== "" &&
                  newFileDrop.Notes !== defaultDrop.Notes
                  ? "text-green-500"
                  : "text-red-500"
              )}
            >
              Notes:
            </label>
            <div className="relative rounded-md shadow-sm">
              <textarea
                id="Notes"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                value={newFileDrop.Notes}
                onChange={(e) =>
                  setNewFileDrop({
                    ...newFileDrop,
                    Notes: e.target.value,
                  })
                }
              />
            </div>
          </div>
          {loading ? (
            <div className="flex flex-row w-full justify-end space-x-2 text-white">
              <p>"Loading..."</p>
            </div>
          ) : (
            <div className="flex flex-row w-full justify-end space-x-2 text-white">
              <button
                className="button hover:text-green-500 font-medium p-2 flex flex-row space-x-2 text-center items-center rounded-md border shadow-md"
                onClick={() => postToFireBase(newFileDrop)}
              >
                <p>Submit</p>
                <VscAdd size={24} />
              </button>

              <button
                className="button hover:text-red-500 font-medium p-2 flex flex-row space-x-2 text-center items-center rounded-md border shadow-md"
                onClick={() => onClose()}
              >
                <p>Cancel</p>
                <VscClose size={24} />
              </button>
            </div>
          )}
        </div>

        {/* debug */}
        <div
          className={clsx(
            "flex flex-col w-full divide-y space-y-2 px-3 py-2 text-white",
            openNodes.debug ? "bg-gray-600 rounded-md shadow-inner" : "border-b"
          )}
        >
          <button
            className={clsx(
              "w-full button font-medium flex flex-row justify-between",
              openNodes.debug
                ? "text-yellow-500 hover:text-yellow-700"
                : "hover:text-yellow-500"
            )}
            onClick={() =>
              setOpenNodes({ ...openNodes, debug: !openNodes.debug })
            }
          >
            <p>Debug</p>
            {openNodes.debug ? <VscClose size={24} /> : <VscBug size={24} />}
          </button>
          {openNodes.debug ? (
            <p className="whitespace-pre font-mono text-xs text-left truncate w-full">
              {JSON.stringify(
                {
                  currentEmail: currentReactAccount?.email,
                  currentuuid: currentReactAccount?.uid,
                  currentDisplayname: currentReactAccount?.displayName,
                  fileDrop: newFileDrop,
                },
                null,
                2
              )}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const AddDropBoxSlideover = (
  {
    isOpen,
    onClose,
    onSubmit,
    sourcePath
  }: {
    onClose: () => void
    onSubmit: () => void|null
    isOpen: DocControl_Standard_Decoder|null
    sourcePath?: string|null
  }
) => {
  const { user } = useAuth();
  return isOpen !== null ? (
    <Slideover
      title={isOpen === defaultDrop? `New DropFile!` : `Editing ${isOpen.Doc_Num}: ${isOpen.Doc_Name}`}
      titleColor={user?"bg-gray-600 border-b":"bg-red-500"}
      isOpen={isOpen !== null}
      onClose={onClose}
      
    >
      {({ onClose }) => (
        <NewFileDropForm onClose={onClose} onSubmit={onSubmit} inDropFile={isOpen} sourcePath={sourcePath}/>
      )}
    </Slideover>
  ) : null;
};

export default AddDropBoxSlideover;