import {CustomReportConfig } from "../../utilites/Decoders/Decoders";

export const reportTypes = ["EmployeeAccounts"];
export const filterOptions = ["DEPARTMENT_ID", "SHIFT_ID", "ACTIVE", "MANAGER"];

//custom cell/row coloring and formating gets added here any std js style object attribute can be added
export const getRowStyles = (row: any, i: number) => {
    //console.log(row);
    return (
        {
            //dont set bg here, its more work to read mouse events...
            //background: (i % 2) === 0 ? '#f1f5f9' : 'white',
        }
    )
}
export const getCellStyles = (cell: any) => {
    //could be worth abstracting all custom colors to vars
    const groupedColor = '#e5e7eb';
    const aggragatedColor = 'white';
    const placeholderColor = 'white';
    const defaultColor = '';

    switch (cell.column.id) {
        default: {// if there is no specific styling provided use this:
            break;
            // return (
            //     {
            //         width: cell.column.getSize(),
            //         background: cell.getIsGrouped()
            //             ? groupedColor
            //             : cell.getIsAggregated()
            //                 ? aggragatedColor
            //                 : cell.getIsPlaceholder()
            //                     ? placeholderColor
            //                     : defaultColor,
            //     }
            // )
        }
    }
    //defaults for aggregates inorder to customize aggragated/ placeholder cell styles this would need to be over ridden/removed. (could easily move the catch block inside each statement)
    return (
        {
            width: cell.column.getSize(),
            background: cell.getIsGrouped()
                ? groupedColor
                : cell.getIsAggregated()
                    ? aggragatedColor
                    : cell.getIsPlaceholder()
                        ? placeholderColor
                        : defaultColor,
        }
    )
}

//used for any dept with out a custom config, partials are used for null elements of an in-use custom config

const masterColumnVisiblity = { 
    "DEBUG": false,
    "ID": true,
    "FIRST_NAME": true,
    "LAST_NAME": true,
    "DEPARTMENT_ID": true,
    "SHIFT_ID": true, 
    "MANAGER": true,    
    "EMAIL_ADDR": true,
    "PHONE": true,
    "PERMISSIONS": true, 
    "ACTIVE": true,
    "PIN": false,
    "PREFRENCES": false, 
    "SIGNITURE": false,
    "EDIT":true,
}

const defaultEmployeeAccountsConfig:CustomReportConfig = {
    customColumnOrder : Object.keys(masterColumnVisiblity),
    customVisibility: {  ...masterColumnVisiblity},
    customSortOrder : [
        {id: 'FIRST_NAME', desc: false},
        {id: 'LAST_NAME', desc: false},
        {id: 'DEPARTMENT_ID', desc: false},
    ],
    customColumnSizes : { 
        "DEBUG": 0,
        "maxSize": Number.MAX_SAFE_INTEGER,
        "minSize": 5,
        "size": 20,
    },
    defaultColumn:null,
    defaultColumns:null
};

//dict of custom report configurations
export const customReports: Record<string, Record<string, CustomReportConfig>>  = {
    //"template": {customColumnOrder: null, customColumnSizes: null, customSortOrder:null, customVisibility:null, defaultColumns:null, defaultColumn:null},
    "EmployeeAccounts": {
        "default": defaultEmployeeAccountsConfig,
    },
}
