import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {connectionStringBase} from "../Globals/Globals";
//import { useAlert } from "react-alert";
import { ParentApiIn, UserAccount } from "../Decoders/Decoders";
import RouterUtility from "../routerUtils";

//sample initalization:
// let cancelSource = axios.CancelToken.source();
// let loading = false;
// const foremansApi = new ParentApi("InfoScreed", cancelSource.token, (e) => {loading = e});

class ParentApi {
  //private alert = useAlert();
  private setStatus: (e:any) => void|null;
  private cancelToken: any;
  private urlParams: string;
  private client: AxiosInstance;
  private DefaultConfiguration = {
    baseURL: connectionStringBase, 
    withCredentials: true 
    //cancelToken: null
  };
  private routerUtility: RouterUtility;
  
  //initialize class
  constructor(urlParams:string, cancelToken:any, setStatus:(e:any)=>void|null){
    this.urlParams = urlParams;
    this.cancelToken = cancelToken;
    this.client = axios.create({...this.DefaultConfiguration, cancelToken: this.cancelToken});
    this.setStatus = setStatus;
    this.routerUtility = new RouterUtility();
  }

  //simple urlparam constructor
  private makeConStr = (customUrl:ParentApiIn) => {
    if(!customUrl.urlUp){
      return this.urlParams
    }else if(customUrl.overWrite){
       return customUrl.urlUp;
    }
    return `${this.urlParams}/${customUrl.urlUp}`; 
  }
  
  //overWrite == true? replace urlPaams : add to end of urlParams
  //the api object should be constructed with url params to match the basic get method.
  //if not include the correct urlparams as a nested custom.

  get = async(customUrl?:ParentApiIn, options?: AxiosRequestConfig) => {
    const connectionstring = customUrl? this.makeConStr(customUrl): this.urlParams;
    return await this.client.get(connectionstring, options?options:undefined)
      .then((res) => {
        if (res.status === 200) {  
          this.setStatus(false); 
          //this.alert.show(`get successfull!`, {type:"success"});
          
          //error tracking
          //this.updateFeErrors("get", true, null, customUrl?connectionstring:null);
          //
          
          return res.data;
        }
        throw Error(`get status ${res.status}`)
      })
      .catch((e) => {
        console.error(`error on: get(${connectionstring})`, e);
        this.setStatus(false); 
        //this.alert.show(`error on: get()`);
        //error tracking
        //this.updateFeErrors("get", false, JSON.stringify(e), customUrl?connectionstring:null);
        //
        return [];
      });
  };

  post = async (NewObject:any, customUrl?:ParentApiIn, nestedCustom?:ParentApiIn) => {
    console.log("posting:", NewObject);
    //return;
    const connectionstring = customUrl? this.makeConStr(customUrl): this.urlParams;
    return await this.client.post(connectionstring, NewObject)
      .then((res) => {
        console.log("post response", res);
        if (res.status === 201) {
          //this.alert.show(`post successfull!`, {type:"success"});
          //this.updateFeErrors("post", true, null, customUrl?connectionstring:null);
          return this.get(nestedCustom);
        }
        throw Error(`post status = ${res.status}`)
      })
      .catch((e) => {
        console.error(`error on: post(${connectionstring})`, e);
        //this.alert.show(`error on: post()`);
        //this.updateFeErrors("post", false, JSON.stringify(e), customUrl?connectionstring:null);
        return this.get(nestedCustom);
      });
  };

  put = async (UpdatedObject: any, customUrl?:ParentApiIn, nestedCustom?:ParentApiIn) => {
    const connectionstring = customUrl? this.makeConStr(customUrl): this.urlParams;
    return await this.client.put(connectionstring, UpdatedObject)
      .then((res) => {
        if (res.status === 204) {
          //this.alert.show(`put successfull!`, {type:"success"});
          //this.updateFeErrors("put", true, null, customUrl?connectionstring:null);
          return this.get(nestedCustom);
        }
        throw Error("put response status = " + res.status);
      })
      .catch((e) => {
        console.error(`error on: put(${connectionstring})`, e);
        //this.alert.show(`error on: put()`);
        //this.updateFeErrors("put", false, JSON.stringify(e), customUrl?connectionstring:null);
        return this.get(nestedCustom);
      });
  };

  delete = async (DeleteId: any, customUrl?:ParentApiIn, nestedCustom?:ParentApiIn) => {
    const connectionstring = customUrl? this.makeConStr(customUrl): this.urlParams;
    return await this.client.delete(`${connectionstring}/${DeleteId}`)
      .then((res) => {
        if (res.status === 200) {
          //this.alert.show(`delete successfull!`, {type:"success"});
          return this.get(nestedCustom);
        }
        throw Error("delete response status = " + res.status);
      })
      .catch((e) => {
        console.error(`error on: delete(${connectionstring})`, e);
        //this.alert.show(`error on: delete()`);
        return this.get(nestedCustom);
      })
  };
  
  getQuickKey = async(key: string) => {
    const connectionstring = `QuickKey/${key}`;
    return await this.client.get(connectionstring)
    .then((res) => {
      if (res.status === 200) {  
        this.setStatus(false); 
        //this.alert.show(`get successfull!`, {type:"success"});
        const returner = JSON.parse((res.data as {key: string, json_data:string}).json_data);
        return returner;
      }
      throw Error(`get status ${res.status}`)
    }).catch((e) => {
      console.error(`error on: get(${connectionstring})`, e);
      this.setStatus(false); 
      //this.alert.show(`error on: get()`);
      return [];
    });
  };

  setQuickKey = async (UpdatedObject: any, key:string, currentReactAccount: UserAccount|null) => {
    const connectionstring = `QuickKey`;
    if(currentReactAccount!== null && currentReactAccount.PERMISSIONS && Number.parseInt(currentReactAccount.PERMISSIONS) > 5){
      return await this.client.put(connectionstring, {key: key, json_data: JSON.stringify(UpdatedObject) })
        .then((res) => {
          if (res.status === 204 || res.status === 201) {
            //this.alert.show(`put successfull!`, {type:"success"});
            return this.getQuickKey(key);
          }
          throw Error("put response status = " + res.status);
        })
        .catch((e) => {
          console.error(`error on: put(${connectionstring})`, e);
          //this.alert.show(`error on: put()`);
          return this.getQuickKey(key);
        });
    }else{
      console.error(`not permitted`);
      //this.alert.show(`Login First!`);
      return null;
    }
  }

  // updateFeErrors = async (meth: string, status: boolean, message:string|null, customUrl?: string|null) => {
  //   try{
  //     const nd = new Date();
  //     const timezoneOffset = nd.getTimezoneOffset(); // Get the timezone offset in minutes
  //     nd.setMinutes(nd.getMinutes() - timezoneOffset); // Adjust the date by the timezone offset   
      
  //     const feErrorUpdate: Fe_Error = {
  //       FE_URL: (this.routerUtility.location.pathname as string),
  //       BE_URL: customUrl? customUrl: this.urlParams,
  //       LAST_USE: nd,
  //       ERROR_COUNT: status? 0 : 1,
  //       ER_MESSAGE: message? message: "NA",
  //       METHOD: meth,
  //       TICKER: 1
  //     }
      
  //     this.client.put("FE_ERROR_TRACKING", feErrorUpdate)
  //       .then((res) => {
  //         if (res.status !== 200) {
  //           console.log("feError update failed: " + res.status);
  //         }else{
  //           //console.log(res.data)
  //         }
  //       })
  //   }catch{
  //      console.log("...update Fe_error catcher...")
  //   }
  // }


  putIP = async (UpdatedObject: any, customUrl?:ParentApiIn) => {
    const connectionstring = customUrl? this.makeConStr(customUrl): this.urlParams;
    return await this.client.put(connectionstring, UpdatedObject, {responseType:"blob"})
      .then((res) => {
        if (res.status === 200) {
          //this.alert.show(`put successfull!`, {type:"success"});
          //this.updateFeErrors("put", true, null, customUrl?connectionstring:null);
          return res;
        }
        throw Error("put response status = " + res.status);
      })
      .catch((e) => {
        console.error(`error on: put(${connectionstring})`, e);
        //this.alert.show(`error on: put()`);
        //this.updateFeErrors("put", false, JSON.stringify(e), customUrl?connectionstring:null);
        return null;
      });
  };

};

export default ParentApi;

