import Slideover from "../slideOvers/Slideover"
import { useState, useEffect } from "react";
import * as R from "ramda";
import React from "react";
import clsx from "clsx";
import { UserAccount } from "../../utilites/Decoders/Decoders";
import { VscBug } from "react-icons/vsc";
import EmployeeAccountApi from "../../utilites/Api/EmployeeAccountApi";

export const EmployeeForm: React.FC<{
  allEmployees: UserAccount[];
  employee: UserAccount;
  onClose: () => void;
  onSubmit: (e:UserAccount) => void|null;
}> = ({ employee, allEmployees, onClose, onSubmit }) => {
  const [debugMode, setDebugMode] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [currentPermission, setCurrentPermission] = useState<string>("0");
  const PERMISSIONS = ["0","1","2","3","4","5","6","7","8","9","10"];
  const employeeAccountApi = new EmployeeAccountApi();
  const [pinError, setPinError] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");
  const [managerError, setManagerError] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [EMAIL_ADDRError, setEMAIL_ADDRError] = useState<string>("");

  const [update, setUpdate] = useState<UserAccount>({
    ID: "",
    FIRST_NAME: "",
    LAST_NAME: "",
    EMAIL_ADDR: "",
    ACTIVE: "Y",
    PIN: undefined,
    DEPARTMENT_ID: "",
    PERMISSIONS: "0",
    PHONE: "", 
    SHIFT_ID: "", 
    MANAGER: "", 
    PREFRENCES: "NONE", 
    SIGNITURE: "",
  });

  useEffect(() => {
    setEMAIL_ADDRError("");
    setPinError("");
    setNameError("");
    setPhoneError("");
    setManagerError("");
    setUpdate({
      ID:            employee.ID           ? employee.ID            : "",
      FIRST_NAME:    employee.FIRST_NAME   ? employee.FIRST_NAME    : "",
      LAST_NAME:     employee.LAST_NAME    ? employee.LAST_NAME     : "",
      EMAIL_ADDR:    employee.EMAIL_ADDR   ? employee.EMAIL_ADDR    : "",
      ACTIVE:        employee.ACTIVE       ? employee.ACTIVE        : "Y",
      PIN:           undefined,
      DEPARTMENT_ID: employee.DEPARTMENT_ID? employee.DEPARTMENT_ID : "",
      PERMISSIONS:   employee.PERMISSIONS  ? employee.PERMISSIONS   : "0",
      PHONE:         employee.PHONE        ? employee.PHONE         : "", 
      SHIFT_ID:      employee.SHIFT_ID     ? employee.SHIFT_ID      : "", 
      MANAGER:       employee.MANAGER      ? employee.MANAGER       : "", 
      PREFRENCES:    employee.PREFRENCES   ? employee.PREFRENCES    : "", 
      SIGNITURE:     employee.SIGNITURE    ? employee.SIGNITURE     : "",
    });
    setCurrentPermission(employee.PERMISSIONS? employee.PERMISSIONS : "0");
  }, [employee]);

  useEffect(() => {
    if (
      update.PIN !== "" &&
      update.PIN !== undefined &&
      update.PIN.length >= 4
    ) {
      validatepinH(update.PIN); //async
    } else {
      if (update.PIN === "") {
        setUpdate({ ...update, PIN: undefined });
      }
      setPinError("");
    }
  }, [update.PIN]);

  const changes: UserAccount = R.pickBy(v => v !== undefined, {
    DEPARTMENT_ID: update.DEPARTMENT_ID !== employee.DEPARTMENT_ID && update.DEPARTMENT_ID !== "" ? update.DEPARTMENT_ID: employee.DEPARTMENT_ID,
    FIRST_NAME:    update.FIRST_NAME    !== employee.FIRST_NAME ? update.FIRST_NAME : employee.FIRST_NAME,
    LAST_NAME:     update.LAST_NAME     !== employee.LAST_NAME  ? update.LAST_NAME : employee.LAST_NAME,
    EMAIL_ADDR:    update.EMAIL_ADDR    !== employee.EMAIL_ADDR && update.EMAIL_ADDR !== "" ? update.EMAIL_ADDR : employee.EMAIL_ADDR,
    ACTIVE:        update.ACTIVE        !== employee.ACTIVE ? update.ACTIVE : employee.ACTIVE,
    PERMISSIONS:   currentPermission    !== employee.PERMISSIONS ? currentPermission : employee.PERMISSIONS,
    PIN:           update.PIN           !== undefined && update.PIN !== "" ? update.PIN : employee.PIN,
    PHONE:         update.PHONE         !== employee.PHONE && update.PHONE !== "" ? update.PHONE : employee.PHONE, 
    SHIFT_ID:      update.SHIFT_ID      !== employee.SHIFT_ID ? update.SHIFT_ID : employee.SHIFT_ID, 
    MANAGER:       update.MANAGER       !== employee.MANAGER ? update.MANAGER : employee.MANAGER, 
    //locked:
    ID: employee.ID,
    PREFRENCES: employee.PREFRENCES !== null? employee.PREFRENCES : JSON.stringify({Name: `${update.FIRST_NAME} ${update.LAST_NAME}`}), 
    SIGNITURE: employee.SIGNITURE,
  });

  function validateEMAIL_ADDR(inputEMAIL_ADDR) {
    if (
      (inputEMAIL_ADDR !== "" &&
        [".com", ".net", ".org", ".edu", ".gov"].includes(
          inputEMAIL_ADDR
            .substring(inputEMAIL_ADDR.length - 4, inputEMAIL_ADDR.length)
            .toLowerCase(),
        ) &&
        inputEMAIL_ADDR.includes("@") &&
        inputEMAIL_ADDR.indexOf("@") !== 0 &&
        inputEMAIL_ADDR.indexOf("@") !== inputEMAIL_ADDR.length - 5 &&
        !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(inputEMAIL_ADDR)) ||
      inputEMAIL_ADDR === employee.EMAIL_ADDR
    ) {
      setEMAIL_ADDRError("");
      return true;
    } else {
      setEMAIL_ADDRError("please enter a valid EMAIL_ADDR");
      return false;
    }
  }

  async function validatepinH(pin) {
    if (pin.length === 4) {
      var fpin = allEmployees.find(emp => emp.PIN === pin)
      if (fpin === undefined) {
        setPinError("");
        return true;
      } else {
        setPinError("pin already exists");
        return false;
      }
    } else {
      setPinError("pin must be 4 digits");
      return false;
    }
  }



  function validateName(FIRST_NAME, LAST_NAME) {
    if (FIRST_NAME.trim() === "" || LAST_NAME.trim() === "") {
      setNameError("please enter both first and last name");
      return false;
    }
    const foundemp = (allEmployees? allEmployees: []).find(
      e => e.FIRST_NAME === FIRST_NAME && e.LAST_NAME === LAST_NAME,
    );
    if (foundemp) {
      setNameError(FIRST_NAME + " " + LAST_NAME + " already exists");
      return false;
    } else {
      setNameError("");
      return true;
    }
  }

  var handleSubmitRow = async () => {
    var validpin = true;
    var validname = true;
    var validEMAIL_ADDR = true;

    if (!employee) {
      throw new Error("employee not yet");
    }

    //check pin input
    if (changes.PIN !== employee.PIN) {
      validpin = await validatepinH(changes.PIN);
    } else {
      setPinError("");
      validpin = true;
    }
    // check name inputs
    if (changes.FIRST_NAME !== employee.FIRST_NAME || changes.LAST_NAME !== employee.LAST_NAME) {
      if (changes.FIRST_NAME !==  employee.FIRST_NAME  && changes.LAST_NAME !== employee.LAST_NAME) {
        validname = validateName(changes.FIRST_NAME, changes.LAST_NAME);
      } else if (changes.LAST_NAME !== employee.LAST_NAME) {
        validname = validateName(employee.FIRST_NAME, changes.LAST_NAME);
      } else if (changes.FIRST_NAME !==  employee.FIRST_NAME ) {
        validname = validateName(changes.FIRST_NAME, employee.LAST_NAME);
      }
    } else {
      setNameError("");
      validname = true;
    }
    //check EMAIL_ADDR input
    if (changes.EMAIL_ADDR !== employee.EMAIL_ADDR) {
      validEMAIL_ADDR = validateEMAIL_ADDR(changes.EMAIL_ADDR);
    } else {
      setEMAIL_ADDRError("");
      validEMAIL_ADDR = true;
    }

    if (validpin && validname && validEMAIL_ADDR) {
      setLoading(true);
      //console.log("Submission Results:", {ID:changes.ID, PIN:changes.PIN, PERMISSIONS:changes.PERMISSIONS, PREFRENCES:changes.PREFRENCES})
      employeeAccountApi.put(changes);
      if(onSubmit){
        onSubmit(changes);
      }
      setLoading(false);
      onClose();
    }
  };

  return (
    <div>
      <div className="px-2">
        <div className="mt-2 flex flex-row w-full space-x-2">
          <p>This form will only effect the permissions and pin values, to change other user attributes please contact HR.</p>
        </div>

        

        <div className="mt-2 flex flex-row w-full space-x-2 items-end">
          <p className="text-red-500">{nameError}</p>
        </div>

        <div className="mt-2 flex flex-row w-full space-x-2 items-end">
          <div className="w-full">
            <label
              htmlFor="FIRST_NAME"
              className={clsx("block text-sm font-medium leading-5 flex flex-row", update.FIRST_NAME !== employee.FIRST_NAME? "text-yellow-500" : "text-gray-700")}
            >
              Edit First Name:
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="FIRST_NAME"
                type="text"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                value={update.FIRST_NAME}
                onChange={e =>
                  setUpdate({ ...update, FIRST_NAME: e.target.value })
                }
              />
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="LAST_NAME"
              className={clsx("block text-sm font-medium leading-5 flex flex-row", update.LAST_NAME !== employee.LAST_NAME? "text-yellow-500" : "text-gray-700")}
            >
              Edit last name:
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="LAST_NAME"
                type="text"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                autoComplete="false"
                value={update.LAST_NAME}
                onChange={e =>
                  setUpdate({ ...update, LAST_NAME: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="mt-2 flex flex-row w-full space-x-2 items-end">
          <div className="w-full">
            <label
              htmlFor="EMAIL_ADDR"
              className={clsx("block text-sm font-medium leading-5 flex flex-row", update.EMAIL_ADDR !== employee.EMAIL_ADDR? "text-yellow-500" : "text-gray-700")}
            >
              Edit Email: <p className="text-red-500 mx-2">{EMAIL_ADDRError}</p>
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="EMAIL_ADDR"
                type="text"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                value={update.EMAIL_ADDR}
                onChange={e => setUpdate({ ...update, EMAIL_ADDR: e.target.value })}
              />
            </div>
          </div>
        </div>

        <div className="mt-2 flex flex-row w-full space-x-2 items-end">
          <div className="w-full">
            <label
              htmlFor="PHONE"
              className={clsx("block text-sm font-medium leading-5 flex flex-row", update.PHONE !== employee.PHONE? "text-yellow-500" : "text-gray-700")}
            >
              Edit Phone: <p className="text-red-500 mx-2">{phoneError}</p>
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="PHONE"
                type="text"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                value={update.PHONE}
                onChange={e => setUpdate({ ...update, PHONE: e.target.value })}
              />
            </div>
          </div>
        </div>

        <div className="mt-2 flex flex-row w-full space-x-2 items-end">
          <div className="w-full">
            <label
              htmlFor="MANAGER"
              className={clsx("block text-sm font-medium leading-5 flex flex-row", update.MANAGER !== employee.MANAGER? "text-yellow-500" : "text-gray-700")}
            >
              Edit Manager: <p className="text-red-500 mx-2">{managerError}</p>
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="MANAGER"
                type="text"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                value={update.MANAGER}
                onChange={e => setUpdate({ ...update, PHONE: e.target.value })}
              />
            </div>
          </div>
        </div>

        <div className="mt-2 flex flex-row w-full space-x-2">
          <div className="w-full">
            <label
              htmlFor="pin"
              className="block text-sm font-medium leading-5 text-gray-700 flex flex-row"
            >
              Edit Pin: <p className="mx-2 text-red-500">{pinError}</p>
            </label>

            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="pin"
                type="password"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                onChange={e => setUpdate({ ...update, PIN: e.target.value })}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col border mt-2 p-2 rounded-md shadow">
          <div className="flex flex-row w-full space-x-2 justify-between">
            <label
              htmlFor="permissions"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Edit Permission Level:
            </label>
            <div>
              <label
                htmlFor="ACTIVE"
                className="block text-sm font-medium leading-5 text-gray-700 flex flex-row"
              >
                Disable user:
                <input
                  type="checkbox"
                  id="ACTIVE"
                  className="my-auto form-checkbox mx-2"
                  checked={update.ACTIVE === "N" ? true : false}
                  onChange={e =>
                    setUpdate({ ...update, ACTIVE: update.ACTIVE === "N"? "Y": "N" })
                  }
                />
              </label>
            </div>
          </div>
          <div className="mt-2 flex flex-row w-full space-x-2 justify-between">
              {PERMISSIONS.map(permission => (
                <button
                  key={permission}
                  disabled={update.ACTIVE === "N"}
                  className={clsx("flex flex-col items-center space-x-2 rounded-md border px-2 shadow-sm font-medium ",
                  update.ACTIVE ==="N"? "bg-red-500 text-red-800":
                  currentPermission === permission? "bg-green-500 text-white" : "hover:bg-green-300 hover:text-green-700")}
                  onClick={e => setCurrentPermission(permission)}
                >
                  <label htmlFor={`${permission}`} >{permission}</label>
                </button>
              ))}
          </div>
        </div>

        <div className="mt-2 flex flex-row w-full justify-between">
            <button className="button bg-yellow-500 text-white hover:bg-yellow-700 font-medium rounded-md px-3 py-1 shadow" onClick={() => setDebugMode(!debugMode)}>
              <VscBug size={24}/>
            </button>
            
            <div className="flex flex-row space-x-2">
              <button
                className="button bg-green-500 text-white hover:bg-green-700 font-medium rounded-md px-3 py-1 shadow"
                onClick={handleSubmitRow}
                disabled={loading || R.isEmpty(changes)}
              >
                Submit
              </button>
              <button
                className="button bg-red-500 text-white hover:bg-red-700 font-medium rounded-md px-3 py-1 shadow"
                onClick={() => onClose()}
              >
                Cancel
              </button>
            </div>
        </div>

        {debugMode ? (
          <div className="whitespace-pre font-mono text-xs text-left">
            {JSON.stringify(changes, null, 2)}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const EditEmployeeSlideover: React.FC<{
  onClose: () => void;
  onSubmit: (e:UserAccount) => void|null;
  employee: UserAccount | null;
  allEmployees: UserAccount[];
}> = ({ onClose, employee, allEmployees, onSubmit }) => {
  return employee ? (
    <Slideover
      title="Edit User Account : "
      isOpen={ employee!== null}
      onClose={onClose}
    >
      {({ onClose }) => (
        <EmployeeForm allEmployees={allEmployees} employee={employee} onClose={onClose} onSubmit={onSubmit}/>
      )}
    </Slideover>
  ) : null;
};

export default EditEmployeeSlideover;
