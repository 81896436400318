import React, { ReactElement } from "react";
import { string, number } from "prop-types";

//custom types can be imported in any component to enforce type resrictions

export interface MODE_D {
  ID: number;
  group?: string;
  permissionLevel: number;
  mode: string;
  path: string;
  element: ReactElement;
  active: boolean;
  loader?: any
}

export interface RAWUSER {
  status: number,
  isAuthenticated: boolean,
  isLibraryAdmin: boolean,
  username: string,
}

export interface AUTHTOKEN {
  userInfo: RAWUSER,
  permissionLevel: number,
}

export type CUSTOMTYPES = "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function" | "null";


export interface Dictionary<T> {
  [Key: string]: T;
}

export interface UserAccount{
  ID: string;
  FIRST_NAME: string;
  LAST_NAME: string;
  PHONE: string;
  DEPARTMENT_ID: string;
  ACTIVE: string;
  SHIFT_ID: string;
  EMAIL_ADDR: string;
  MANAGER: string;
  PERMISSIONS: string;
  PREFRENCES: string;
  SIGNITURE: any;
  PIN: string;
}

export interface ParentApiIn{
  overWrite:boolean;
  urlUp:string;
}

export interface CustomReportConfig {
  customColumnOrder: string[] | null;
  customVisibility: Record<string, boolean> | null;
  customSortOrder: { id: string; desc: boolean }[] | null;
  customColumnSizes: Record<string, number> | null;
  defaultColumns: any | null;
  defaultColumn: any | null;
}

export interface PROPKEY {
  Key: string,
  KeyType: CUSTOMTYPES,
  error: number
}

export interface DocControl_Standard_Decoder {
  Doc_ID: number;
  Doc_Num: string;
  Doc_Name: string;
  Doc_Type: string;
  Doc_Date: string;
  Revision: string;
  Master_Location: string;
  Release_Date: string | null;
  Doc_Status: string;
  Notes: string;
  Doc_Category: string;
  Obsolete_Date: string | null;
  File_Path: string;
  Division: string | null;
  Department: string | null;
  IsWebPath: boolean;
  ForceRevControl: boolean;
  RevisionNotes: string | null;
  Doc_Manager: string;
  Vendor: string;
  Customer: string | null;
  Part: string | null;
  Reference: string;
  ErpSource: string | null;
  ErpSourceId: string | null;
  CustomerName: string | null;
  VendorName: string | null;
}

export const defaultDrop: DocControl_Standard_Decoder = {
  Doc_ID: -1,
  Doc_Num: "",
  Doc_Name: "",
  Doc_Type: "",
  Doc_Date: "",
  Revision: "",
  Master_Location: "",
  Release_Date: "",
  Doc_Status: "",
  Notes: "",
  Doc_Category: "",
  Obsolete_Date: "",
  File_Path: "",
  Division: "",
  Department: "",
  IsWebPath: false,
  ForceRevControl: false,
  RevisionNotes: "",
  Doc_Manager: "",
  Vendor: "",
  Customer: "",
  Part: "",
  Reference: "",
  ErpSource: "",
  ErpSourceId: "",
  CustomerName: "",
  VendorName: "",
};

export interface attachedSupplier {
  AttachDescription: string;
  AttachPath:string;
  AttachType:string;
  Attachment:number;
  Doc_ID: number;
  Doc_Num:string;
  Doc_Type:string;
  Sup_description:string;
}