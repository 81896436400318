import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { ReactUserAccount } from "../../utilites/Globals/Atoms";
import RouterUtility from "../../utilites/routerUtils";
import ParentApi from "../../utilites/Api/ParentApi";
import axios from "axios";
import clsx from "clsx";
import { BiRightArrow, BiRightArrowAlt } from "react-icons/bi";
import { connectionStringBase } from "../../utilites/Globals/Globals";
import sortBy from 'sort-by';

const shortcutList = [
  {keys:"CTRL+SHIFT+R", name:"Refresh", use:"hard reset page and remove cached version"},
  {keys:"CTRL+F", name:"Find text", use:"search page for text"},
  {keys:"CTRL+SHIFT+I", name:"Dev Tools", use:"view errors and information, helpfull for trouble shooting"},
  {keys:"Tab", name:"Select next", use:"focus on the next availible input field/button"},
  {keys:"CRTL+P", name:"Print Page", use:"prints the current page"},
  {keys:"CRTL+SHIFT+S", name:"Screenshot", use:"takes a screenshot of the current page, works better than printing"},
]

const WebMonitor: React.FC<React.PropsWithChildren<React.PropsWithChildren<{}>>> = () => {
  let cancelSource = axios.CancelToken.source();
  let loading = false;
  const routerUtility = new RouterUtility();
  const [currentReactAccount, setCurrentReactAccount] = useRecoilState(ReactUserAccount);
  const [forceRefresh, setForceRefresh] = useState<boolean>(false);
  const projectApi = new ParentApi("FE_ERROR_TRACKING", cancelSource.token, (e) => {loading = e});
  const rubyRunnerApi = new ParentApi("RubyRunner", cancelSource.token, (e) => {loading = e});
  const [feErrors, setFeErrors] = useState<any[]>([]);
  const [rubyLoading, setRubyLoading] = useState<boolean>(false);
  const [rubyResults, setRubyResults] = useState<{file:string, res:string}|null>(null);
  const [keysDown, setKeysDown] = useState<string[]>([]);
  const sTimestamp = new Date();
  sTimestamp.setSeconds(sTimestamp.getSeconds() + 60)
  const [expiryTimestamp, setExpiryTimestamp] = useState<Date>(sTimestamp);

//   useEffect(() => {
//     const fetchData = async () => {
//         let temp: Fe_Error[]|undefined = await projectApi.get({overWrite:false, urlUp:""});
//         console.log(temp);
//         if(temp != undefined){
//             // const srt = temp.filter(x => x.ERROR_COUNT > 0).map(x => ({...x, LAST_USE: new Date(x.LAST_USE) })).sort((a,b) =>  
//             // a.ERROR_COUNT === b.ERROR_COUNT? 
//             //   a.METHOD === b.METHOD?  
//             //     a.FE_URL.localeCompare(b.FE_URL)
//             //   : a.METHOD < b.METHOD? 1: -1
//             // : a.ERROR_COUNT > b.ERROR_COUNT? 1: -1
//             // )
//             const srt = temp.filter(x => x.ERROR_COUNT > 0).map(x => ({...x, LAST_USE: new Date(x.LAST_USE)})).sort(sortBy("LAST_USE"));
//             const lsts = temp.filter(x => x.ERROR_COUNT === 0).map(x => ({...x, LAST_USE: new Date(x.LAST_USE)})).sort(sortBy("-LAST_USE")).slice(0,15);
//             setFeErrors([...srt, ...lsts]);
//             return;
//         }
//     };

//     if(forceRefresh === false && loading === false){
//         loading = true;
//         setForceRefresh(true);
//         cancelSource = axios.CancelToken.source();

//         fetchData().catch((e) => console.error("FeErrorTracking Error", e)).then(() =>{
//         //console.log("starting timer")
//         const nTimestamp = new Date();
//         nTimestamp.setSeconds(nTimestamp.getSeconds() + 600)
        
//         setExpiryTimestamp(nTimestamp);
//         });

//         return () => {
//             if(cancelSource && loading){
//                 //console.error("canceling all fetchs")
//                 cancelSource.cancel();
//             }else{
//                 console.log("no need to cancel")
//             }
//         }
//     } 
  
// }, [forceRefresh]);

  const runRuby = async (path: string) => {
    try{
      let upPath = path.split("/").join("_");
      upPath = upPath.split(".").join("~");
      
      await rubyRunnerApi.get({overWrite:false, urlUp:`${upPath}`}).then(x => {
        console.log("ruby runner results", x);
        setRubyLoading(false);

        const parsed = (x as string).split("]~[");
        if(parsed.length >1){
          parsed[0] = parsed[0].substr(1);
          parsed[1] = parsed[1].substr(0, parsed[1].length-1);
          setRubyResults({file:parsed[0], res:parsed[1]});
        }else{
          throw "bad output: " + (x as string);
        }

      })


    }catch(ex){
      console.log("ruby runner error:", ex);
      clearRuby(false);
    }
  }

  const clearRuby = (lad:boolean) => {
    setRubyResults(null);
    setRubyLoading(lad);
  };

  const handleKey = (e: string, m:string) => {
    switch (e) {
      // @ts-ignore
      case "CONTROL":
        e = "CTRL";
        
      default:
        if(!keysDown.includes(e) && m === "d"){
          setKeysDown(keys => {
            return [...keys, e];
          });
        }else if(m === "u"){
          setKeysDown(keys => {
            return keys.filter(x => x !== e);
          });
        }
      break;
    }
    //console.log(keysDown);
  }

  const useShortcuts = useMemo(()=> {
    return shortcutList.map(x => ({...x, keys: x.keys.split("+")})).sort(sortBy("name"));
  }, [shortcutList, keysDown])

  return (
    <div tabIndex={0} className={clsx("flex w-full divide-black flex-col overflow-hidden h-screen bg-gray-500 px-2 pb-2 ")} onKeyDown={(e) => handleKey(e.key.toLocaleUpperCase(), "d")} onKeyUp={(e) => handleKey(e.key.toLocaleUpperCase(), "u")}>

      {/* load manager
      <div className="flex flex-col justify-center refresh hidden"> 
          <div className="flex flex-col"><RefreshTimer forceRefresh={forceRefresh} setForceRefresh={setForceRefresh} expiryTimestamp={expiryTimestamp}/></div>
      </div> */}


      <div className={clsx("flex flex-row w-full overflow-hidden space-x-2")}>
        
        <div className="flex flex-col flex-1" >
          <div className={clsx("flex flex-row text-left w-full px-2 bg-slate-800 drop-shadow overflow-hidden font-semibold text-sm border-b border-black")}>
            <label className="flex flex-row p-1 flex-none text-lg text-white items-baseline">Shortcuts <p className="flex flex-col px-2 text-slate-500 text-sm">click anywhere inside a page before using a shortcut (like here for instance)</p></label>
          </div>
          
          <div className={clsx("flex flex-col w-full h-full overflow-hidden bg-slate-800 rounded-b-md p-1 text-white divide-y divide-black")}>
              {useShortcuts.map(x => 
                <div key={x.name} className="flex flex-col w-full text-left px-2 py-1 font-semibold">
                  <div className={clsx("flex flex-row w-full overflow-hidden space-x-2")}>
                    <label className=" w-1/10">{x.name}</label>
                     
                    <div className={clsx("flex flex-row flex-none w-1/4 overflow-hidden space-x-2")}>
                      {x.keys.map((y, j) => 
                          <div key={`${y}${j}`} className="flex flex-row space-x-2">
                            <p className={clsx("px-2 rounded-md text-sm text-center py-0.5 border shadow-md", keysDown.includes(y)? "bg-green-300 text-green-800 border-green-800" : "bg-slate-200 text-black border-black")}>
                              {y}
                            </p>
                            <p className={clsx("", j < x.keys.length-1? "":"hidden")}>+</p>
                          </div>
                        )}
                    </div>

                    <label className="flex-1">{x.use}</label>
                   
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className="flex flex-col w-1/2 hidden">
          <div className={clsx("flex flex-row text-left w-full px-2 bg-slate-800 drop-shadow overflow-hidden font-semibold text-sm border-b border-black")}>
            <label className="flex flex-row p-1 flex-none text-lg text-white items-baseline">Toolkit <p className="flex flex-col px-2 text-slate-500 text-sm">(dont click buttons if you dont know what they do)</p></label>
            <label className={clsx("flex flex-col text-red-500 justify-center text-center", rubyLoading? "" : "hidden")}>Loading...</label>
          </div>
          <div className={clsx("flex flex-row text-left w-full px-2 py-1 bg-slate-800 drop-shadow overflow-hidden font-semibold border-b border-black space-x-2")}>
            
            <button disabled={rubyLoading} className={"darkenDisabled flex flex-col flex-none px-2 bg-green-300 text-green-800 hover:bg-green-500 rounded-md border-green-800 shadow"} onClick={() => {clearRuby(true); runRuby("sopRunner.rb")} }>
              Update QSP
            </button>

            <button disabled={rubyLoading} className={"darkenDisabled flex flex-col flex-none px-2 bg-green-300 text-green-800 hover:bg-green-500 rounded-md border-green-800 shadow"} onClick={() => {clearRuby(true); runRuby("sdsRunner.rb")} }>
              Update SDS
            </button>

            <button disabled={rubyLoading} className={"darkenDisabled flex flex-col flex-none px-2 bg-green-300 text-green-800 hover:bg-green-500 rounded-md border-green-800 shadow"} onClick={() => {clearRuby(true); runRuby("runTester.rb")} }>
              Tester
            </button>

            <button disabled={rubyLoading} className={"darkenDisabled flex flex-col flex-none px-2 bg-green-300 text-green-800 hover:bg-green-500 rounded-md border-green-800 shadow"} onClick={() => clearRuby(false)}>
              Clear
            </button>

          </div>
          <div className={clsx("flex flex-col w-full h-52 overflow-hidden bg-slate-800 rounded-b-md p-1")}>
            <div className="flex flex-col text-left flex-1 bg-white border border-black rounded-md overflow-x-hidden overflow-y-auto text-sm font-medium">
              <p className={"flex flex-row w-full flex-none bg-amber-500 px-1 border-b border-black shadow"}>File:</p>
              <p className="flex flex-row w-full flex-none px-4">{rubyLoading? "Loading..." :rubyResults === null? "Run tool to see results": `${rubyResults.file}`}</p>
              <p className={"flex flex-row w-full flex-none bg-amber-300 px-1 border-b border-black shadow"}>Results:</p>
              <p className={"flex-1 px-4"}>{rubyResults? rubyResults.res: "-na-"}</p>
            </div>
          </div>
        </div>
      </div>


      
      <div className={clsx("flex flex-row text-left w-full px-2 bg-slate-800 drop-shadow overflow-hidden font-semibold text-sm rounded-t-md mt-2")}>
        <label className="flex flex-row p-1 w-full text-lg text-white items-baseline">Monitor <p className="flex flex-col px-2 text-slate-500 text-sm">To report errors or get help, please contact Rittir.Frankowski@ArchGp.com </p></label>
      </div>



      <div className={clsx("flex flex-row border-t divide-x divide-black text-left w-full px-2 bg-slate-800 drop-shadow overflow-hidden font-semibold text-sm border-b border-black")}>
        <p className="text-green-500 p-1">Status = Good: Last Request successfully completed, Time = last request time</p>
        <p className="text-red-500 p-1">Status = Bad(x): Last Request Failed, Time = First Failure time, x = failed attempts</p>
        <p className="text-white p-1">FE Links navigate to the error location</p>
        <p className="text-white p-1">BE Links can validate if the back end works</p>
      </div>

      {/* header row */}
      <div className="flex flex-row divide-x divide-black bg-slate-800 text-white font-semibold drop-shadow border-b border-black">
        <label className={clsx("flex w-24 flex-none px-2 truncate text-center justify-center py-1")}>
          Method
        </label> 

        <label className={clsx("flex w-24 flex-none px-2 truncate text-center justify-center py-1")}>
          Status
        </label>

        <label className={clsx("flex w-24 flex-none px-2 text-center justify-center py-1")}>
          Last use
        </label>

        <label className={clsx("flex w-24 flex-none px-2 text-center justify-center py-1")}>
          Time
        </label>

        <div className="flex flex-row space-x-1 flex-1">
          <label className={clsx("flex w-1/3 flex-none px-2 truncate items-center align-center py-1 space-x-4")}>
            <p>Front end URL</p>
            <p className="text-slate-400">FE: ArchDox.com</p>
          </label>

          <label className={clsx("flex flex-1 px-2 truncate items-center align-center py-1 space-x-4")}>
            <p>Back end URL</p>
            <p className="text-slate-400">BE: FireBase</p> 
            <p className="text-slate-400">*BE Links only work on get Methods*</p>
          </label>
        </div>

        <label className={clsx("flex w-24 flex-none px-2 text-center justify-center py-1")}>
          Ticker
        </label>
      </div>  
      
      {/* main wrapper */}
      <nav className={clsx("flex flex-col flex-1 overflow-x-hidden overflow-y-auto space-y-0.5")}>
        {feErrors.map((x, i) => 
          <div key={`${i}`} className="flex flex-col divide-y divide-black drop-shadow bg-gray-300 hover:bg-white">
            
            <div className="flex flex-row divide-x divide-black">
              
              <label className={clsx("flex w-24 flex-none px-2 truncate bg-slate-800 text-center justify-center", x.ERROR_COUNT <= 0? x.METHOD !== "get"? "text-blue-500": "text-white" : "text-red-500")}>
                Http {x.METHOD}
              </label> 
              
              <label className={clsx("flex w-24 flex-none px-1 truncate bg-slate-800 text-center justify-center", x.ERROR_COUNT > 0? "text-red-500": "text-green-500")}>
                {x.ERROR_COUNT === 0? "Good" : `Bad (${x.ERROR_COUNT})`}
              </label>

              <label className={clsx("flex w-24 flex-none px-1 text-center justify-center", x.ERROR_COUNT > 0? "text-red-500": "")}>
                {x.LAST_USE.toLocaleDateString()}
              </label>

              <label className={clsx("flex w-24 flex-none px-1 truncate text-center justify-center", x.ERROR_COUNT > 0? "text-red-500": "")}>
                {x.LAST_USE.toLocaleTimeString()}
              </label>

              <div className="flex flex-row space-x-1 flex-1">
                
                <label className={clsx("flex w-1/3 flex-none truncate px-1 hover:text-blue-600")}>
                  <a href={x.FE_URL}  target="_blank" rel="noopener noreferrer">
                    {x.FE_URL}
                  </a>
                </label>
                
                <label className={clsx("flex flex-1 px-1 truncate", x.ERROR_COUNT <= 0? "" : "text-red-500", x.METHOD === "get"? "hover:text-blue-600": "hover:text-red-500" )}>
                  <BiRightArrowAlt size={24}/>
                  {x.METHOD === "get"? 
                    <a href={`http://www.seabrook.com/api/${x.BE_URL}`} target="_blank" rel="noopener noreferrer">
                      <p>{x.BE_URL}</p>
                    </a>
                  :
                    <><p>{x.BE_URL}</p> <p className="text-slate-500">({x.METHOD.toUpperCase()})</p></>
                  }
                </label>
                
              </div>

              <label className={clsx("flex w-24 flex-none px-2 text-center justify-center py-1")}>
                {x.TICKER}
              </label>
            </div>  

            <div className={clsx("flex flex-row divide-x divide-black", x.ER_MESSAGE? "": "hidden")}>
               <label className={clsx("flex w-24 flex-none truncate bg-slate-800 px-1 text-red-500")}>Error:</label> 
               <label className={clsx("flex flex-1 px-1 truncate")}> {x.ER_MESSAGE} </label>
            </div>  

          </div>
        )}
        {/* footer */}
        <div className="flex flex-row divide-x divide-black bg-slate-800 text-white drop-shadow rounded-b-md border-t border-black">
          <label className={"flex w-24 flex-none px-2 truncate text-center justify-center"}>
            End
          </label>
        </div>
      </nav>

    </div>
  );
};

export default WebMonitor;