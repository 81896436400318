import { Dialog, Transition } from "@headlessui/react";
import { BiX as XIcon} from "react-icons/bi";
import React from "react";
import clsx from "clsx";
import { Fragment, useRef } from "react";

const Slideover: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  afterLeave?: () => void;
  children: ({ onClose }) => JSX.Element | null;
  className?: string;
  title: string;
  titleColor?: string;
  Buttons?: JSX.Element;
}> = ({ isOpen, onClose, afterLeave, title, children, className, Buttons, titleColor }) => {
  const closeButtonRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment} afterLeave={afterLeave}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden z-20"//added z-20 3/1/2024
        open={isOpen}
        onClose={onClose}
        initialFocus={closeButtonRef}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 slideover" />

          <div className="fixed inset-y-0 right-0 sm:pl-10 max-w-full overflow-x-hidden flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200 sm:duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200 sm:duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={clsx(
                  "w-screen z-40",
                  className ? className: "md:max-w-2xl lg:max-w-xl",
                )}
              >
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="min-h-0 flex-1 flex flex-col overflow-x-hidden overflow-y-auto">
                    <div className={clsx("px-4 sm:px-6 py-3.5", titleColor? titleColor: "bg-red-600")}>
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          {title}
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            className="rounded-md text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => onClose()}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {children({ onClose })}
                  </div>
                  {Buttons ? Buttons : null}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Slideover;
