import React from "react";
import "./App.css";
import { RecoilRoot } from "recoil";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Root from "./Pages/main/root";
import ErrorPage from "./Pages/main/error-page";
import { Webpages } from "./utilites/Globals/Globals";
import { AuthProvider } from "./utilites/Authprovider";
import axios from "axios";
import clsx from "clsx";

const defaultLoader = () => {
  return {};
};

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      loader: defaultLoader,
      children: Webpages.map((page) => ({
        path: page.path,
        element: page.element,
        loader: defaultLoader,
      })),
    },
  ],
  {
    // baseurl?
    // basename:'/build'
  }
);

function App() {
  return (
    <div className="App flex flex-col overflow-hidden">
      <AuthProvider>
        <RecoilRoot>
            {/* <StartingMenu /> */}
            <RouterProvider router={router} />
        </RecoilRoot>
      </AuthProvider>
    </div>
  );
}

export default App;
