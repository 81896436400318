import { useNavigate, useParams, useNavigation } from "react-router";
import {useLocation } from 'react-router-dom';
import localforage from "localforage";

class RouterUtility {
    public navigate = useNavigate();
    public location = useLocation() as any;
    public urlParams = useParams();
    public navigation = useNavigation();

    //custom <a href="" .../> override used when linking to an entirely new location (localhost:3000/new_loaction/this_donest_count)
    //could be easily expanded to accept optional options, such as replace
    public linkTo = async (e:any, params: string, state?:any, cache?:{name:string, data:any}) => {
        if(e){
            e.preventDefault();// prevent the default navigation from the <a>
        }
        
        if(cache && state){
            console.log(`linking to ${params} with state & setting local ${cache.name}`)
            await localforage.setItem(cache.name, JSON.stringify({...cache.data}))
            .then(()=> this.navigate(params, {state: state}));
        }else if(state){
            console.log(`linking to ${params} with state`)
            this.navigate(params, {state: state})
        }else{
            //console.log(`linking to ${params}, no state/cache`)
            this.navigate(params)
        }
    }

    public navigateTo = async (params: string, state?:any, options?:any, cache?:{name:string, data:any}) => {
        if(cache && state){
            console.log(`linking to ${params} with state & setting local ${cache.name}`)
            await localforage.setItem(cache.name, JSON.stringify({...cache.data}))
            .then(()=> this.navigate(params, {state: state, ...options}));
        }else if(state){
            console.log(`linking to ${params} with state`)
            this.navigate(params, {state: state, ...options})
        }else{
            console.log(`linking to ${params}, no state/cache`)
            this.navigate(params, {...options})
        }
    }

    //custom used to update the current pages local caches / state, essentially linkTo the current location (localhost:3000/same_loaction/this_could_be_new)
    //key difference in  {...optiosn, replace:true}
    public setCache = async (state?:{params:string, state?:any}, cache?:{name:string, data:any} ) => {
        if(cache && state){
            //console.log(`updated state of ${state.params} & local ${cache.name}`)
            await localforage.setItem(cache.name, cache.data).catch((e) => console.error(`set local (with state) ${cache.name} fail`, e))
            .then(() => this.navigate(state.params, {state: state.state, replace:true}));
        }else if(state){
            //console.log(`updated state of ${state.params}`)
            this.navigate(state.params, {state: state.state, replace:true})
        }else if(cache){
            //console.log(`updated local ${cache.name}}`)
            await localforage.setItem(cache.name, cache.data).catch((e) => console.error(`set local ${cache.name} fail`, e))
        }else{
            console.error("no state or log to cache...")
        }
    }

    //return the state variable for the given page, if there is no state but a key is provided try using the local storage key.
    public getCache = async (cacheName?:string) => {
        let res = null;
        if(this.location.state){
            //console.log("state found: ", this.location.state)
            res = this.location.state
        }else if(cacheName){
            await localforage.getItem(cacheName).catch((e) => {console.error("local cache read error", e);})
            .then((cachedItem : any) =>{
                //console.log(`local ${cacheName} cache found: `, cachedItem);
                res = cachedItem;
                localforage.removeItem(cacheName);
            })
        }else{
            console.log("no state or cache found...")
        }
        return res;    
    }    

    public getLocal = async (key:string, clean:boolean = true) => {
        return await localforage.getItem(key)
        .catch((e) => {
            console.error(`local ${key} cache read error`, e); 
            return null;
        })
        .then((cachedItem : any) => {
            //console.log(`get local ${key} result (cleaning:${clean})`, cachedItem);
            if(clean === true){
                localforage.removeItem(key);
            }
            return cachedItem;
        })
    }

};

export default RouterUtility;