import React, { useMemo } from "react";
import { Outlet, matchPath } from "react-router";
import { useState, useEffect } from "react";
import { Webpages } from "../../utilites/Globals/Globals";
import {
  BiUserCircle,
  BiArrowFromLeft,
  BiX,
  BiFolder,
  BiFolderOpen,
} from "react-icons/bi";
import { useRecoilState } from "recoil";
import { CurrentUser, ReactUserAccount } from "../../utilites/Globals/Atoms";
import UserProfileSlideover from "../../utilites/slideOvers/UserProfileSlideover";
import clsx from "clsx";
//import uuid from "react-uuid";
import localforage from "localforage";
import { UserAccount, MODE_D } from "../../utilites/Decoders/Decoders";
import RouterUtility from "../../utilites/routerUtils";
import { User, UserCredential } from "firebase/auth";
import { useAuth } from "../../utilites/Authprovider";


const Root: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<{}>>
> = () => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [openUserProfile, setOpenUserProfile] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [pageGroups, setPageGroups] = useState<
    { group: string; open: boolean }[]
  >([]);
  const [availPages, setAvailPages] = useState<any>({});
  const routerUtility = new RouterUtility();
  const { user } = useAuth();

  const loadUser = async (act: User | null) => {
    //console.log("current User", act);
    if (act !== null) {
      setCurrentUser(act);
      const lastPage = await routerUtility
        .getLocal("lastPage", false)
        .then((e) => (e !== null ? e : "/"));

      //console.log("clearing localstorage WA");
      //localforage.clear();

      if ((routerUtility.location.pathname as string) === "/") {
        routerUtility.navigateTo(lastPage, null, { replace: true });
      }
    } else {
      setCurrentUser(null);
      //console.log("clearing localstorage NA");
      localforage.clear();

      const pathstr = routerUtility.location.pathname as string;
      // console.log("curloc", pathstr);
      // let page = Webpages.find((x) => x.path === pathstr);
      //if (page && page.permissionLevel > 0) {
      if(pathstr !== "/"){  
        routerUtility.navigateTo("/");
      }
      //}
    }
  };

  useEffect(() => {
    loadUser(user);
  }, [user]);


  //if you are not on the index page, log the page as your last visted page
  useEffect(() => {
    if ((routerUtility.location.pathname as string) !== "/") {
      const strLoc = routerUtility.location.pathname as string;
      routerUtility.setCache(undefined, { name: "lastPage", data: strLoc });
      // localforage.setItem('lastPage', routerUtility.location.pathname);
    }
  }, [routerUtility.location]);

  //generate availible webpages
  useEffect(() => {
    let aPages: MODE_D[] = [];

    if (currentUser === null) {
      aPages = Webpages.filter(
        (page) => page.active && page.ID >= 0 && page.permissionLevel === 0
      );
    } else {
      aPages = Webpages.filter(
        (page) =>
          page.active &&
          page.ID >= 0 
          //&& page.permissionLevel <= 0 + currentReactAccount.PERMISSIONS
      );
    }

    const groups = Array.from(new Set(aPages.map((x) => x.group)))
      .sort((a, b) => -a.localeCompare(b))
      .map((x) => ({ group: x, open: true }))
      .reverse();
    setPageGroups(groups);

    const mapped = {};

    groups.forEach((x) => {
      mapped[x.group ? x.group : "Def"] = aPages
        .filter((y) => y.group === x.group)
        .sort((a, b) => a.mode.localeCompare(b.mode));
    });
    setAvailPages(mapped);
  }, [currentUser]);

  const handleGroupClick = (group: { group: string; open: boolean }) => {
    setPageGroups((oldData) => {
      const newData = [...oldData];
      const idx = newData.findIndex((x) => x.group === group.group);
      newData[idx] = { ...group, open: !group.open };
      return [...newData];
      //oldData.find(x => x.group === group.group).open = !group.open;
      //return [...oldData]
    });
  };

  return (
    <div className="flex flex-col overflow-hidden h-screen w-screen">
      {/* user profile init */}
      <UserProfileSlideover
        isOpen={openUserProfile}
        onClose={() => setOpenUserProfile(false)}
        onSubmit={() => console.log("profile submitted: ")}
      />

      {/* title bar*/}
      <header className="flex flex-row bg-gray-800 justify-between w-full px-2 border-b">
        {/* left side */}
        <div className="flex flex-col w-auto my-1">
          <p className="flex flex-row text-white font-bold">
            Arch Medical Solutions
          </p>
          <button
            className="text-white hover:text-sky-500 space-x-1 flex flex-row items-center"
            onClick={() => setOpenMenu(!openMenu)}
          >
            {!openMenu ? (
              <>
                <p>
                  {Webpages.find(
                    (page) =>
                      matchPath(page.path, routerUtility.location.pathname) !==
                      null
                  )
                    ? Webpages.find(
                        (page) =>
                          matchPath(
                            page.path,
                            routerUtility.location.pathname
                          ) !== null
                      ).mode
                    : "Open Menu"}
                </p>
                <BiArrowFromLeft size={20} />
              </>
            ) : (
              <>
                <BiFolderOpen className="text-yellow-500" size={20} />
                <p className="text-yellow-500">Available Pages</p>
                <BiX size={20} />
              </>
            )}
          </button>
        </div>

        {/*right side*/}
        <div
          className="flex flex-col w-auto my-1 cursor-pointer"
          onClick={() => setOpenUserProfile(true)}
        >
          <div className="flex flex-row items-center justify-between">
            <label
              className={clsx(
                "",
                !currentUser ? "text-red-500" : "text-green-500 capitalize"
              )}
            >
              {user ? (
                <p className="font-semibold text-green-500 capitalize pr-1">
                  {user.email?.split("@")[0]}
                </p>
              ) : (
                <p className="font-semibold text-red-500 capitalize pr-1">
                 Sign in here
                </p>
              )}
            </label>
            <BiUserCircle
              size={20}
              className={clsx(
                "flex flex-col ",
                !currentUser ? "text-red-500" : "text-green-500"
              )}
            />
          </div>
          <div className="flex flex-row">
            <p className="text-white text-right">
              {new Date().toLocaleDateString()}
            </p>
            <p className="text-left text-green-500">
              {new Date().toLocaleTimeString()}
            </p>
          </div>
        </div>
      </header>

      {/*main content*/}
      <div className="flex flex-row w-full h-full overflow-hidden">
        {/* side bar */}
        {openMenu ? (
          <nav className="flex flex-col h-full bg-slate-800 drop-shadow overflow-x-hidden overflow-y-auto scrollbar-hide w-72 flex-none border-r">
            {/* <ul className="w-full truncate overflow-x-hidden"> */}
            {pageGroups.map((page) => (
              //folder maping
              <ul key={`${page.group}`}>
                {page.group ? (
                  //folder name
                  <button
                    className="cursor-pointer flex flex-row text-yellow-500 hover:text-yellow-800 items-center px-2"
                    onClick={() => handleGroupClick(page)}
                  >
                    {page.open ? (
                      <BiFolderOpen size={20} />
                    ) : (
                      <BiFolder size={20} />
                    )}
                    <p
                      className={clsx(
                        "text-left truncate text-lg font-smeibold"
                      )}
                    >
                      {page.group}
                    </p>
                  </button>
                ) : null}

                {!page.group
                  ? //no group/folder
                    availPages["Def"].map((act: MODE_D) => (
                      <li className="px-4 flex flex-row" key={`${act.mode}`}>
                        <p className="text-yellow-500">|</p>{" "}
                        <a href={act.path}>
                          <p
                            className={clsx(
                              "pl-4 text-white hover:text-green-500 text-left truncate"
                            )}
                          >
                            {act.mode}
                          </p>
                        </a>
                      </li>
                    ))
                  : page.open
                  ? //grouped items
                    availPages[page.group].map((act: MODE_D) => (
                      <li className="px-4 flex flex-row" key={`${act.mode}`}>
                        <p className="text-yellow-500">|</p>{" "}
                        <a href={act.path}>
                          <p
                            className={clsx(
                              "pl-4 text-white hover:text-green-500 text-left truncate"
                            )}
                          >
                            {act.mode}
                          </p>
                        </a>
                      </li>
                    ))
                  : null}
              </ul>
            ))}
            <li className="px-4 flex flex-row invisible">
              <p className="text-yellow-500">|</p>
              <p
                className={clsx(
                  "pl-4 text-white hover:text-green-500 text-left truncate"
                )}
              >
                spacer
              </p>
            </li>
            {/* </ul> */}
          </nav>
        ) : (
          <></>
        )}

        {/* content */}
        <div
          className={"flex flex-col w-full h-full bg-slate-400 overflow-hidden"}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Root;
