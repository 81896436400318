// Modal.tsx
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { attachedSupplier } from "../../utilites/Decoders/Decoders";
import clsx from "clsx";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  items: attachedSupplier[];
  onSubmit: (x:string) => void; 
  inSup:string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, closeModal, items, onSubmit, inSup }) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-slate-800 border border-sky-800 shadow-xl rounded-lg">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                <label className="flex flex-row space-x-1 text-white">
                  <p>Sites using </p>
                  <p className="">{items[0] ? items[0].Doc_Num : "NA"}</p>
                </label>
              </Dialog.Title>
              <div className="mt-2">
                <ul className="space-y-1">
                  {items.map((item, index) => (
                    <li
                      key={item.AttachPath}
                      className={clsx(
                        "py-1 px-2 rounded-md",
                        inSup === item.AttachPath
                          ? "bg-green-800 hover:bg-green-500 hover:text-green-800 text-green-300 border-green-800"
                          : "bg-slate-700  hover:bg-gray-100 border-gray-500 text-gray-500"
                      )}
                      onClick={() => onSubmit(item.AttachPath)}
                    >
                      {item.Sup_description}
                    </li>
                  ))}

                  <li className={clsx("py-1 px-2 rounded-md bg-sky-800 -hover:bg-sky-500 -hover:text-sky-800 text-sky-300 border-sky-800")}>
                    Email "kparault@archgp.com" to add your site
                  </li>

                </ul>
              </div>
              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-sky-300 bg-sky-800 hover:bg-sky-500 hover:text-sky-800 border-sky-800 border rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-sky-500"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
