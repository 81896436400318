import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  KeyboardEvent,
  createContext,
  useCallback,
  useContext,
} from "react";
import axios from "axios";
import ParentApi from "../../utilites/Api/ParentApi";
import clsx from "clsx";
import { useRecoilState } from "recoil";
import { ReactUserAccount } from "../../utilites/Globals/Atoms";
import RouterUtility from "../../utilites/routerUtils";
import {
  BiPlus,
  BiMinus,
  BiMessageAdd,
  BiMessageAltAdd,
  BiMessageAltX,
  BiMessageAltCheck,
  BiMessageAltDots,
  BiTrash,
  BiSave,
} from "react-icons/bi";
import { object } from "prop-types";
import {
  getColorConverter,
  colorLookUp,
  reverseLookup,
} from "../../utilites/Decoders/ColorConverter";
import { BsArrow90DegUp, BsBoxArrowUpRight, BsFillArrowUpRightCircleFill } from "react-icons/bs";

const defaultUrlParamKeys = {
  isValid: "false",
  userLocation: "main",
};

const Welcome: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<{
      fullScreenIn?: boolean;
    }>
  >
> = ({ fullScreenIn }) => {

  return (
    <div
      className={clsx(
        "relative flex w-full divide-black flex-col overflow-hidden h-screen justify-center items-center align-center bg-slate-600"
      )}
    >
      {/* blurring */}

      {/*main window*/}
      <div
        className={clsx(
          "flex flex-col flex-none p-2 relative overflow-hidden bg-white rounded border shadow-lg"
        )}
      >
        <label className={clsx("flex flex-row font-medium text-lg space-x-2 justify-center items-center align-center")}>
          <p>Click the profile icon to sign in and continue</p> <BsBoxArrowUpRight size={20}/>
        </label>
        <label className={clsx("flex text-gray-500 text-sm flex-row space-x-1")}>
          <p>for login issues please contact</p> 
          <p className="text-md font-semibold">Rittir.frankowski@ArchGp.com</p>
          <p>from your company email</p>
        </label>
      </div>
    </div>
  );
};

export default Welcome;
