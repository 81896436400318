import Slideover from "../slideOvers/Slideover"
import { useState, useEffect, useMemo } from "react";
import * as R from "ramda";
import React from "react";
import clsx from "clsx";
import { UserAccount } from "../../utilites/Decoders/Decoders";
import { VscBug } from "react-icons/vsc";

export const AddEmployeeForm: React.FC<{
  adding: boolean;
  onClose: () => void;
  onSubmit: (e) => void|null;
}> = ({ adding, onClose, onSubmit }) => {
  const [debugMode, setDebugMode] = useState<boolean>(false);
  const PERMISSIONS = [0,1,2,3,4,5,6,7,8,9,10];
  const currentEmployees: UserAccount[] = []
  const [loading, setLoading] = useState(false);
  const [currentPermission, setCurrentPermission] = useState<number>(0);

  const [pinError, setPinError] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [EMAIL_ADDRError, setEMAIL_ADDRError] = useState<string>("");

  const [update, setUpdate] = useState<UserAccount>({
    ID: "",
    FIRST_NAME: "",
    LAST_NAME: "",
    PIN: "",
    EMAIL_ADDR: "",
    ACTIVE: "",
    DEPARTMENT_ID: "",
    PERMISSIONS: "0",
    PHONE: "", 
    SHIFT_ID: "", 
    MANAGER: "", 
    PREFRENCES: "NONE", 
    SIGNITURE: "",
  });

  useEffect(() => {
    if (
      update.PIN !== "" &&
      update.PIN !== undefined &&
      update.PIN.length >= 4
    ) {
      validatepinH(update.PIN); //async
    } else {
      setPinError("");
    }
  }, [update.PIN]);

  const changes: UserAccount = R.pickBy(v => v !== undefined, {
    ID:            `${update.LAST_NAME.substr(0,3)}${update.FIRST_NAME.substr(0,3)}01`,
    FIRST_NAME:    update.FIRST_NAME    !== "" ? update.FIRST_NAME : "",
    LAST_NAME:     update.LAST_NAME     !== "" ? update.LAST_NAME  : "",
    PIN:           update.PIN           !== "" ? update.PIN        : "",
    EMAIL_ADDR:    update.EMAIL_ADDR    !== "" ? update.EMAIL_ADDR : "",
    ACTIVE:        update.ACTIVE        !== "" ? update.ACTIVE     : "",
    PHONE:         update.PHONE         !== "" ? update.PHONE      : "", 
    SHIFT_ID:      update.SHIFT_ID      !== "" ? update.SHIFT_ID   : "", 
    MANAGER:       update.MANAGER       !== "" ? update.MANAGER    : "", 
    PREFRENCES:    update.PREFRENCES    !== "" ? update.PREFRENCES : "",
    SIGNITURE:     update.SIGNITURE     !== "" ? update.SIGNITURE  : "",    
    DEPARTMENT_ID: update.DEPARTMENT_ID !== "" ? update.DEPARTMENT_ID : "NONE",
    PERMISSIONS:   currentPermission,
  });

    // const changes: UserAccount = useMemo(() => {
    //   return {
    //     ID: `${update.LAST_NAME.substr(0, 3)}${update.FIRST_NAME.substr(0, 3)}01`,
    //     FIRST_NAME: update.FIRST_NAME !== "" ? update.FIRST_NAME : "",
    //     LAST_NAME: update.LAST_NAME !== "" ? update.LAST_NAME : "",
    //     PIN: update.PIN !== "" ? update.PIN : "",
    //     EMAIL_ADDR: update.EMAIL_ADDR !== "" ? update.EMAIL_ADDR : "",
    //     ACTIVE: update.ACTIVE !== "" ? update.ACTIVE : "",
    //     PHONE: update.PHONE !== "" ? update.PHONE : "",
    //     SHIFT_ID: update.SHIFT_ID !== "" ? update.SHIFT_ID : "",
    //     MANAGER: update.MANAGER !== "" ? update.MANAGER : "",
    //     PREFRENCES: update.PREFRENCES !== "" ? update.PREFRENCES : "",
    //     SIGNITURE: update.SIGNITURE !== "" ? update.SIGNITURE : "",
    //     DEPARTMENT_ID:
    //       update.DEPARTMENT_ID !== "" ? update.DEPARTMENT_ID : "NONE",
    //     PERMISSIONS: `${currentPermission}`
    //   }
    // }, [update, currentPermission, ]);

  function validateEMAIL_ADDR(inputEMAIL_ADDR) {
    if (
      inputEMAIL_ADDR !== "" &&
      [".com", ".net", ".org", ".edu", ".gov"].includes(
        inputEMAIL_ADDR
          .substring(inputEMAIL_ADDR.length - 4, inputEMAIL_ADDR.length)
          .toLowerCase(),
      ) &&
      inputEMAIL_ADDR.includes("@") &&
      inputEMAIL_ADDR.indexOf("@") !== 0 &&
      inputEMAIL_ADDR.indexOf("@") !== inputEMAIL_ADDR.length - 5 &&
      !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(inputEMAIL_ADDR)
    ) {
      setEMAIL_ADDRError("");
      return true;
    } else {
      setEMAIL_ADDRError("please enter a valid EMAIL_ADDR");
      return false;
    }
  }

  async function validatepinH(pin) {
    if (pin.length === 4) {
      var fpin = await checkPin(pin);
      if (fpin === 0) {
        setPinError("");
        return true;
      } else {
        setPinError("pin already exists");
        return false;
      }
    } else {
      setPinError("pin must be 4 digits");
      return false;
    }
  }

  async function checkPin(pin: string){
    return 0;
  }

  function validateName(FIRST_NAME, LAST_NAME) {
    if (FIRST_NAME === "" || LAST_NAME === "") {
      setNameError("please enter both first and last name");
      return false;
    }
    const foundemp = (currentEmployees? currentEmployees : []).find(e => e.FIRST_NAME === FIRST_NAME && e.LAST_NAME === LAST_NAME);

    if (foundemp) {
      setNameError(FIRST_NAME + " " + LAST_NAME + " already exists");
      return false;
    } else {
      setNameError("");
      return true;
    }
  }

  const handleSubmitRow = async () => {
    var validpin = true;
    var validname = true;
    var validEMAIL_ADDR = true;

    if (changes.PIN !== undefined) {
      validpin = await validatepinH(changes.PIN);
    } else {
      setPinError("please enter a pin");
      validpin = false;
    }

    if (changes.FIRST_NAME && changes.LAST_NAME) {
      validname = validateName(changes.FIRST_NAME, changes.LAST_NAME);
    } else {
      setNameError("please enter both a first and last name");
      validname = false;
    }

    if (changes.EMAIL_ADDR !== undefined) {
      validEMAIL_ADDR = validateEMAIL_ADDR(changes.EMAIL_ADDR);
    } else {
      setEMAIL_ADDRError("please enter an EMAIL_ADDR");
      validEMAIL_ADDR = false;
    }

    if (validpin && validname && validEMAIL_ADDR) {
      setLoading(true);
      console.log("Submission Results:", changes)
      if(onSubmit){
        onSubmit(changes);
      }
      setLoading(false);
      onClose();
    }
  };

  return (
    <div>
      <div className="px-2">
      <div className="mt-2 flex flex-row w-full space-x-2">
          <button className="button bg-red-500 text-white hover:bg-red-700 font-medium rounded-md px-3 py-1 shadow" onClick={() => setDebugMode(!debugMode)}>
            {debugMode ? (
              <div className="whitespace-pre font-mono text-xs text-left">
                {JSON.stringify(changes, null, 2)}
              </div>
            ) : null}
            <VscBug size={24}/>
          </button>
          <p>This form will only effect the permissions and pin values, to change other user attributes please contact HR.</p>
        </div>

        <div className="mt-2 flex flex-row w-full space-x-2 items-end">
          <p className="text-red-500">{nameError}</p>
        </div>

        <div className="mt-2 flex flex-row w-full space-x-2 items-end">
          <div className="w-full">
            <label
              htmlFor="FIRST_NAME"
              className="block text-sm font-medium leading-5 text-gray-700 flex flex-row"
            >
              *First Name:
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="FIRST_NAME"
                type="text"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                required
                onChange={e =>
                  setUpdate({ ...update, FIRST_NAME: e.target.value })
                }
              />
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="LAST_NAME"
              className="block text-sm font-medium leading-5 text-gray-700 flex flex-row"
            >
              *Last Name:
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="LAST_NAME"
                type="text"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                required
                autoComplete="false"
                onChange={e =>
                  setUpdate({ ...update, LAST_NAME: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="mt-2 flex flex-row w-full space-x-2">
          <div className="w-full">
            <label
              htmlFor="EMAIL_ADDR"
              className="block text-sm font-medium leading-5 text-gray-700 flex flex-row"
            >
              *EMAIL_ADDR: <p className="text-red-500 mx-2">{EMAIL_ADDRError}</p>
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="EMAIL_ADDR"
                type="text"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                onChange={e => setUpdate({ ...update, EMAIL_ADDR: e.target.value })}
              />
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="pin"
              className="block text-sm font-medium leading-5 text-gray-700 flex flex-row"
            >
              *Pin:<p className="text-red-500 mx-2">{pinError}</p>
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="pin"
                type="password"
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                onChange={e => setUpdate({ ...update, PIN: e.target.value })}
              />
            </div>
          </div>
        </div>

        <div className="mt-2 flex flex-row w-full space-x-2">
          <div className="w-full">
            <label
              htmlFor="department"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Department
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="department"
                type="text"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                autoComplete="false"
                onChange={e =>
                  setUpdate({ ...update, DEPARTMENT_ID: e.target.value })
                }
              />
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="PHONE"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Phone number
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                id="PHONE"
                type={"text"}
                autoComplete="false"
                className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
                onChange={e => setUpdate({ ...update, PHONE: e.target.value })}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col border mt-2 p-2 rounded-md shadow">
          <div className="flex flex-row w-full space-x-2 justify-between">
            <label
              htmlFor="permissions"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Permission Level:
            </label>
            <div>
              <label
                htmlFor="ACTIVE"
                className="block text-sm font-medium leading-5 text-gray-700 flex flex-row"
              >
                Disable user:
                <input
                  type="checkbox"
                  id="ACTIVE"
                  className="my-auto form-checkbox mx-2"
                  checked={update.ACTIVE !== "Y" ? true : false}
                  onChange={e =>
                    setUpdate({ ...update, ACTIVE: (update.ACTIVE === "Y"? "N" : "Y") })
                  }
                />
              </label>
            </div>
          </div>
          <div className="mt-2 flex flex-row w-full space-x-2 justify-between">
              {PERMISSIONS.map(permission => (
                <button
                  key={permission}
                  disabled={update.ACTIVE === "N"}
                  className={clsx("flex flex-col items-center space-x-2 rounded-md border px-2 shadow-sm font-medium ",
                  update.ACTIVE? "bg-red-500 text-red-800":
                  currentPermission === permission? "bg-green-500 text-white" : "hover:bg-green-300 hover:text-green-700")}
                  onClick={e => setCurrentPermission(permission)}
                >
                  <label htmlFor={`${permission}`} >{permission}</label>
                </button>
              ))}
          </div>
        </div>

        <div className="mt-2 flex flex-row w-full space-x-2 justify-end">
            <button
              className="button bg-green-500 text-white hover:bg-green-700 font-medium rounded-md px-3 py-1 shadow"
              onClick={handleSubmitRow}
              disabled={loading || R.isEmpty(changes)}
            >
              Submit
            </button>
            <button
              className="button bg-red-500 text-white hover:bg-red-700 font-medium rounded-md px-3 py-1 shadow"
              onClick={() => onClose()}
            >
              Cancel
            </button>
          </div>
      </div>

    </div>
  );
};

const AddEmployeeSlideover: React.FC<{
  onClose: () => void;
  onSubmit: (e:UserAccount) => void|null;
  adding: boolean;
}> = ({ onClose, adding, onSubmit }) => {
  return adding ? (
    <Slideover
      title="Add New User Account"
      isOpen={adding === true}
      onClose={onClose}
    >
      {({ onClose }) => <AddEmployeeForm adding={false} onClose={onClose} onSubmit={onSubmit}/>}
    </Slideover>
  ) : null;
};

export default AddEmployeeSlideover;
