import Slideover from "./Slideover"
import { useState, useEffect, useRef, KeyboardEvent} from "react";
import React from "react";
import clsx from "clsx";
import { UserAccount} from "../Decoders/Decoders";
import { VscBug, VscClose } from "react-icons/vsc";
import {BsDoorClosed, BsAlarm, BsPalette} from "react-icons/bs";
import { useRecoilState } from "recoil";
import { BiUserCircle } from "react-icons/bi";
import localforage from "localforage";
import RouterUtility from "../routerUtils";
import {
  User,
} from "firebase/auth";
import { useAuth } from "../Authprovider";
import { defaultPage } from "../Globals/Globals";

export const SignInReactAccount = (
  {onClose}: {onClose: () => void}
) => {
  const [namePass, setNamePass] = useState<{ID:string, PIN:string}>({ID:"", PIN:""});
  const [loading, setLoading] = useState<string>("");

  const [signingUp, setSigningUp] = useState<boolean>(false);

  const { signIn, signUp, resetPassword } = useAuth();

  const routerUtility = new RouterUtility();
  const headerInputRefs = useRef<
    (HTMLInputElement | HTMLButtonElement | null)[]
  >([]);

  const CreateReactAccount = async () => {
    if (namePass.ID.trim() !== "" && namePass.PIN.length >= 4) {
      try {
        setLoading("signing up...");
        const temp: User | null = await signUp(namePass.ID, namePass.PIN).then(
          (e) => e.user
        );



        if (temp) {
          routerUtility.setCache(undefined, {
            name: "lastPage",
            data: defaultPage,
          });
          routerUtility.navigateTo(defaultPage);

          setLoading("Sign-up successful!");
          return;
        } else {
          throw new Error("Sign-up failed.");
        }
      } catch (e) {
        localforage.removeItem("currentReactAccount");
        console.log("login error", e);
        setLoading(e.message);
      }
    } else {
      namePass.ID.trim() === ""
        ? setLoading("error: please enter a valid email")
        : setLoading("error: please enter a valid password");
    }
  };

  const LoginReactAccount = async () => {
    if (namePass.ID.trim().length > 0 && namePass.PIN.length >= 4) {
      try{
        setLoading("Singing in...");
        const temp: User | null = await signIn(namePass.ID, namePass.PIN).then(e => e.user);
        
        if (temp) {
          routerUtility.setCache(undefined, {
            name: "lastPage",
            data: defaultPage,
          });
          routerUtility.navigateTo(defaultPage);

          setLoading("Login Successfull...")
          return;
        } else {
          throw new Error("login failed..");
        }
      }catch(e){
        localforage.removeItem('currentReactAccount')
        console.log("login error", e)
        setLoading(e.message);
      } 
    } else {
      namePass.ID.trim() === ""
        ? setLoading("error: please enter a valid email")
        : setLoading("error: please enter a valid password");
    }
  };

   const ResetAccount = async () => {
     if (namePass.ID.trim() !== "") {
       try {
         setLoading("sending reset...");
         resetPassword(namePass.ID).then(() => setLoading("check email for reset..."));
       } catch (e) {
        console.log(e);
         setLoading("reset error: " + e.message);
       }
     } else {
      setLoading("enter a valid email address...")
     }
     localforage.removeItem("currentReactAccount");
   };

  //implement use ref to enable form controls
  //remove lenth 4 logic
  //add login submit to on key down using the use ref
   const headerKeyPress = (
     e:
       | KeyboardEvent<HTMLInputElement>
       | null,
     currentIndex: number
   ) => {
     if (e === null || e.key === "Enter") {
       if (e !== null) {
         e.preventDefault();
       }
       

       let nextIndex = currentIndex + 1;
        if(nextIndex >= 2){
          LoginReactAccount();
          return;
        }

       while (
         headerInputRefs.current[nextIndex] &&
         headerInputRefs.current[nextIndex].disabled &&
         nextIndex < headerInputRefs.current.length - 1
       ) {
         nextIndex += 1;
       }

       if (headerInputRefs.current[nextIndex]) {
         headerInputRefs.current[nextIndex].focus();
       } else {
         headerInputRefs.current[0].focus();
       }
     }
   };

  return (
    <div
      className="p-2 flex flex-col w-full h-full space-y-2"
      onSubmit={() => LoginReactAccount()}
    >
      <form className="flex flex-col w-full space-x-2 items-end">
        <div className="w-full">
          <label
            htmlFor="username"
            className={clsx(
              "block text-sm font-medium leading-5 flex flex-row",
              namePass.ID !== "" ? "text-green-500" : "text-red-500"
            )}
          >
            Email:
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              ref={(el) => (headerInputRefs.current[0] = el)}
              onKeyDown={(e) => headerKeyPress(e, 0)}
              id="username"
              type="text"
              autoComplete="false"
              className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
              value={namePass.ID}
              onChange={(e) => setNamePass({ ...namePass, ID: e.target.value })}
            />
          </div>
        </div>

        <div className="w-full">
          <label
            htmlFor="password"
            className={clsx(
              "block text-sm font-medium leading-5 flex flex-row",
              namePass.PIN.length < 4 ? "text-red-500" : "text-green-500"
            )}
          >
            password:
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              ref={(el) => (headerInputRefs.current[1] = el)}
              onKeyDown={(e) => headerKeyPress(e, 1)}
              id="password"
              type="password"
              className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded-md border"
              autoComplete="false"
              value={namePass.PIN}
              onChange={(e) =>
                setNamePass({ ...namePass, PIN: e.target.value })
              }
            />
          </div>
        </div>
      </form>
      <div className="mt-2 flex flex-row w-full space-x-2 align-center items-center">
        <p className="flex-1 truncate overflow-hidden text-red-500">
          {loading}
        </p>

        <button
          className="button bg-red-500 text-white hover:bg-red-700 font-medium rounded-md px-3 py-1 shadow"
          onClick={() => ResetAccount()}
        >
          Reset pass.
        </button>

        <button
          className="button bg-amber-500 text-white hover:bg-amber-700 font-medium rounded-md px-3 py-1 shadow"
          onClick={() => CreateReactAccount()}
        >
          Sign up
        </button>

        <button
          ref={(el) => (headerInputRefs.current[2] = el)}
          onKeyDown={(e) => headerKeyPress(null, 2)}
          className="button bg-green-500 text-white hover:bg-green-700 font-medium rounded-md px-3 py-1 shadow"
          onClick={() => LoginReactAccount()}
        >
          Sign in
        </button>

        {/* <button
          className="button bg-red-500 text-white hover:bg-red-700 font-medium rounded-md px-3 py-1 shadow"
          onClick={() => onClose()}
        >
          Cancel
        </button> */}
      </div>
    </div>
  );
}


export const UserProfile = (
  {
    onClose,
    onSubmit
  }: {
    onClose: () => void
    onSubmit: (e:UserAccount) => void|null
  }
) => {
  const [openNodes, setOpenNodes] = useState<{profileDetails: boolean, notifications:boolean, prefrences:boolean, debug:boolean}>({profileDetails:false, notifications:true, prefrences:false, debug:false})
  const { signout, user } = useAuth();

  const currentReactAccount = user;

  const signOut = () => {
    signout();
    localforage.clear();
  }

  return (
      <div className="h-full overflow-hidden">
        {!currentReactAccount? <SignInReactAccount onClose={onClose}/> :
         <div className="flex flex-col h-full w-full bg-gray-800 pb-2 overflow-x-hidden overflow-y-hidden text-white justify-between"> 
          {/* top message */}
          {/* <label className="block text-sm font-medium text-red-500 leading-5 flex flex-row px-2 text-center justify-center">*To Update Your User information, please contact HR*</label> */}
          
          {/* main content */}
          <div className="flex flex-col h-full w-full overflow-x-hidden overflow-y-auto space-y-1"> 
            {/* Profile details */}
            <div className={clsx("flex flex-col w-full divide-y space-y-2 px-3 py-2", openNodes.profileDetails? "bg-gray-600 rounded-md shadow-inner": "border-y")}>
              <button className={clsx("w-full button font-medium flex flex-row justify-between", openNodes.profileDetails? "text-green-500 hover:text-green-700": "hover:text-green-500")}  onClick={() => setOpenNodes({...openNodes, profileDetails:!openNodes.profileDetails})}>
                <p>Profile Details</p>{openNodes.profileDetails?<VscClose size={24}/> :<BiUserCircle size={24}/>}
              </button>
              {openNodes.profileDetails? 
                <div className="flex flex-row ">
                  {/* Detail view */}
                  <div className="flex flex-col pr-1">

                    <label className="block text-sm font-medium leading-5 flex flex-row">Contact Info:</label>
                    <div className="flex flex-col w-full">       
                      <p className={"pl-4 pr-2 font-mono text-xs text-left"}>
                        {currentReactAccount? currentReactAccount.email: "No Email"}
                      </p>

                      <p className={"pl-4 pr-2 font-mono text-xs text-left"}>
                        {currentReactAccount && currentReactAccount.phoneNumber? currentReactAccount.phoneNumber: "No Phone"}
                      </p>
                    </div>

                    <label className="block text-sm font-medium leading-5 flex flex-row">Permission Information:</label>
                    <div className="flex flex-col w-full">
                      <p className={"pl-4 pr-2 font-mono text-xs text-left"}>
                        {currentReactAccount.displayName}
                      </p>
                    </div>
                  </div>
                </div>
              :
                null
              }
            </div>

            {/*Notifications */}
            <div className={clsx("flex flex-col w-full divide-y space-y-2 px-3 py-2", openNodes.notifications? "bg-gray-600 rounded-md shadow-inner": "border-b")}>
              <button className={clsx("w-full button font-medium flex flex-row justify-between", openNodes.notifications? "text-red-500 hover:text-red-700": "hover:text-red-500")}  onClick={() => setOpenNodes({...openNodes, notifications:!openNodes.notifications})}>
                <p>Notifications</p>{openNodes.notifications?<VscClose size={24}/> :<BsAlarm size={24}/>}
              </button>
              {openNodes.notifications? 
                <p>open</p>
              :
                null
              }
            </div>

            {/*prefrences */}
            <div className={clsx("flex flex-col w-full divide-y space-y-2 px-3 py-2", openNodes.prefrences? "bg-gray-600 rounded-md shadow-inner": "border-b")}>
              <button className={clsx("w-full button font-medium flex flex-row justify-between", openNodes.prefrences? "text-sky-500 hover:text-sky-700": "hover:text-sky-500")} onClick={() =>setOpenNodes({...openNodes, prefrences:!openNodes.prefrences})}>
                <p>Prefrences</p>{openNodes.prefrences?<VscClose size={24}/> :<BsPalette size={24}/>}
              </button>
              {openNodes.prefrences? 
                <p>open</p>
              :
                null
              }
            </div> 

            {/*debug */}
            <div className={clsx("flex flex-col w-full divide-y space-y-2 px-3 py-2", openNodes.debug? "bg-gray-600 rounded-md shadow-inner": "border-b")}>
              <button className={clsx("w-full button font-medium flex flex-row justify-between", openNodes.debug? "text-yellow-500 hover:text-yellow-700": "hover:text-yellow-500")} onClick={() => setOpenNodes({...openNodes, debug:!openNodes.debug})}>
                  <p>Debug</p>{openNodes.debug?<VscClose size={24}/> : <VscBug size={24}/>}
              </button>
              {openNodes.debug ? 
                <p className="whitespace-pre font-mono text-xs text-left truncate w-full">
                  {JSON.stringify({currentReactAccount:currentReactAccount}, null, 2)}
                </p>
              : 
                null
              }
            </div>
            
          </div>
          {/* <SignInAnimation/> */}
          {/*sign out */}
          <button className="button hover:text-red-500 font-medium px-3 py-2 flex flex-row justify-between border-y" onClick={() => signOut()}>
            <p>Signout</p><BsDoorClosed size={24}/>
          </button>
          
          {/*close */}
          <button className="button hover:text-red-500 font-medium px-3 py-2 flex flex-row justify-between border-b" onClick={() => onClose()}>
            <p>Close</p><VscClose size={24}/>
          </button>

        </div>
        }
    </div>
  );
};

const UserProfileSlideover = (
  {
    isOpen,
    onClose,
    onSubmit,
  }: {
    onClose: () => void
    onSubmit: () => void|null
    isOpen: boolean
  }
) => {
  const { user } = useAuth();
  return isOpen ? (
    <Slideover
      title={user? `${user.email}` : "Sign In: "}
      titleColor={user?"bg-gray-600 border-b":"bg-red-500"}
      isOpen={isOpen}
      onClose={onClose}
      
    >
      {({ onClose }) => (
        <UserProfile onClose={onClose} onSubmit={onSubmit}/>
      )}
    </Slideover>
  ) : null;
};

export default UserProfileSlideover;