// import React from 'react';
import {
  // RecoilRoot,
  atom,
  // selector,
  // useRecoilState,
  // useRecoilValue,
} from 'recoil';
// import date from "date-and-time";
// import { guestToken } from './Globals';

//atoms are global state variables, think usestate but they can be accessed from any component
//useRecoil state will subscribe the component to updates (reload on change)
//useSetRecoilState will update the state with out subscribing


//this relates to the current machine* not neccassarily the individual user of the machine
export const CurrentUser = atom({
    key: 'CurrentUser', // unique ID (with respect to other atoms/selectors)
    default: {
      userInfo: {
        status: 404,
        isAuthenticated: false,
        isLibraryAdmin: false,
        username: "Guest",
      }, permissionLevel: 0
    } // default value (aka initial value)
});

export const ReactUserAccount = atom({
  key:'ReactUserAccount',
  default: null
  // {
  //   ID: "INVALID",
  //   FIRST_NAME: null,
  //   LAST_NAME: null,
  //   PHONE: null,
  //   DEPARTMENT_ID: null,
  //   ACTIVE: null,
  //   SHIFT_ID: null,
  //   EMAIL_ADDR: null,
  //   MANAGER: null,
  //   PERMISSIONS: null,
  //   PREFRENCES: null,
  //   SIGNITURE: null,
  //   PIN: null
  // }
})

export const userPrefrences = atom({
  key: 'userPrefrences', // unique ID (with respect to other atoms/selectors)
  default: null 
  //{
  //   homePageID: 10,
  //   cachedFileHandles: null 
  // } // default value (aka initial value)
});

//timer atoms
// export const TimeStamp = atom({
//     key: 'TimeStamp',
//     default: new Date()
// });

// export const StopTimer = atom({
//   key: 'StopTimer',
//   default: false
// });
